import styled from 'styled-components';

export default styled.div`
    /* position: relative; */
    width: 100%;

    .sku-block {
        background: #fff;
        cursor: pointer;
    }

    .goods-sku-detail {
        display: flex;
        flex-wrap: wrap;
    }

    .close-buy-now-icon {
        width: 24px;
        height: 24px;
        display: inline-block;
        background: #666;
    }
    .sku-title {
        ${(props) => props.theme.getBaseFontAll([14, 16])};
        color: var(--color_body_text);
        margin: ${(props) => props.theme.getSpace([10, 16])} 0;
    }
    .sku-title-num {
        margin-bottom: ${(props) => props.theme.getSpace([8, 12])};
    }
    
    
    }
    .sku-item {
        padding: 10px ${(props) => props.theme.getSpace([10, 16])};
        cursor: pointer;
        font-weight: 400;
        color: #9b9ea4;
        border-radius: 2px;
        margin: 0 ${(props) => props.theme.getSpace([10, 16])} ${(props) => props.theme.getSpace([10, 16])} 0;
        box-shadow: 0 0 0 0.5px var(--color_small_button_text_border);
        color: var(--color_small_button_text_border);
        &.active {
            border: 2px solid var(--color_small_button_text_border);
        }
    }

    .goods-buy-button {
        margin-top: ${(props) => props.theme.getSpace([18, 24])}
    }

    .add-shop-car {
        width: 100%;
        line-height: 54px;
        background: #fff;
        font-size: ${(props) => props.theme.getBaseFontSize([14, 16])};
        font-weight: 500;
        color: #041b4a;
        cursor: pointer;
        border: 1px solid #415272;
    }
    .goods-sell-out-btn {
        color: #b9bfc9;
        background: #f7f8fa;
        border: 1px solid #b9bfc9;
    }

    .zent-number-input {
        display: flex !important;
        .zent-number-input-arrow-disable {
            background: transparent !important;
        }
    }
    .operation-item {
        display: flex;
        /* margin: 0 -var(--pm-16-10) 0; */
        margin-top: ${(props) => props.theme.getSpace([18, 24])};
        width: 100%;
    }
    .sku-line {
        margin-top: var(--pm-24-16);
        text-align: left;
    }
    .sku-line + .operation-item {
        margin-top: ${(props) => props.theme.getSpace([18, 24])}
    }

    .number-input-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
        .zent-number-input {
            border-radius: var(--border_radius_btn) !important;
        }
    }
    .limit-tips {
      display: flex;
      align-items: center;
      color: rgba(var(--color_text_rgb), 0.4);
      font-size: 12px;
      line-height: 16px;
    }

    @media screen and (max-width: 750px) {
        width: 100%;
        .operation-item {
            width: 100%;
        }
        .zent-number-input {
            margin-bottom: 32px;
        }
    }
`;
