import React from 'react';
import SkuList from 'cpn/sku-list';
import { NumberInput, Button } from 'zent';
import { Style, SkuSelectStyle } from './style';
import { useIntl } from 'react-intl';
import { HtPrice, GoodsPrice } from 'cpn/goods-price';
import { useCart } from 'hook/cart/cart';

export const ShopModal = (props) => {
  const { goodsDetail, history, currency, onClose, activityId } = props;
  const { cart, maxCartNum, onNumChange, onSkuSelect, onGroupBuying } = useCart({
    goodsDetail,
    history,
  });

  const intl = useIntl();
  if (!goodsDetail) return null;
  return (
    <Style>
      <div className='mobile-mask' />
      <div className='sku-container animate-sku'>
        <div className='sku-block'>
          <div className='sku-close-line'>
            <span className='price'>
              {!cart?.variantId ? (
                <GoodsPrice goodsDetail={goodsDetail} currency={currency} />
              ) : (
                <HtPrice price={cart?.price} currency={currency} />
              )}
            </span>
            <svg
              className='close-buy-now-icon'
              onClick={(event) => {
                event.stopPropagation();
                onClose();
              }}
              aria-hidden='true'
            >
              <use xlinkHref='#iconic-shanchu' />
            </svg>
          </div>

          <SkuSelectStyle>
            <SkuList
              cart={cart}
              options={goodsDetail?.options}
              variants={goodsDetail?.variants}
              withVariants={goodsDetail?.withVariants}
              onSkuSelect={onSkuSelect}
            />
            <div className='sku-line operation-item'>
              <p className='sku-title sku-title-num'>
                {intl.formatMessage({ description: '数量', id: 'common_count' })}
              </p>
              <div className='number-input-wrapper'>
                <NumberInput value={cart.quantity} showCounter max={maxCartNum} min={1} onChange={onNumChange} />
              </div>
            </div>

            <Button
              className='operation-item'
              onClick={() => {
                onGroupBuying({
                  activityId,
                });
              }}
            >
              {intl.formatMessage({
                id: '01722335147e4738a1ebc7f485c00fc5',
                defaultMessage: '发起拼团',
              })}
            </Button>
          </SkuSelectStyle>
        </div>
      </div>
    </Style>
  );
};
