import FeaturedRow from '../sections/featured-row';
import SlideShow from '../sections/slide-show';
import SlideShowV2 from '../sections/slide-show-v2';
import SlideShowV3 from '../sections/slide-show-v3';
import Hero from '../sections/hero';
import Quotes from '../sections/quotes';
import QuotesV2 from '../sections/quotes-v2';
import FeaturedContent from '../sections/featured-content';
import LogoBar from '../sections/logo-bar';
import Collection from '../sections/collection';
import CollectionV2 from '../sections/collection-v2';
import CollectionV3 from '../sections/collection-v3';
import ProductGrid from '../sections/product-grid';
import CollectionList from '../sections/collection-list';
import Video from '../sections/video';
import ImageBar from '../sections/image-bar';
import FeaturedGrid from '../sections/featured-grid';
import FeaturedProducts from '../sections/featured-products';
import preFetchFeaturedProductsData from '../sections/featured-products/get-initial-props';
import FeaturedColumns from '../sections/feature-columns';
import Header from '../sections/header';
import preFetchHeaderData from '../sections/header/get-initial-props';
import HeaderV2 from '../sections/header-v2';
import preFetchHeaderV2Data from '../sections/header-v2/get-initial-props';
import AnnouncementBar from '../sections/announcement-bar';
import Footer from '../sections/footer';
import Newsletter from '../sections/newsletter';
// import PopList from '../sections/pop-list';
import SectionSpacing from '../sections/section-spacing';
import ComprehensiveNav from '../sections/comprehensive-nav';
import Chocolate from '../sections/chocolate';

import SlideVideoPic from '../sections/slide-video-pic';
import CollectionCoverPic from '../../goods-list/sections/collection-cover-pic';

import Clothes from '../sections/clothes';
import RecommendProduct from '../../goods-detail/components/recommend-product';
import SimpleProduct from '../sections/simple-product';
import FeaturedGroupBuying from '../sections/featured-group-buying';
import GroupBuyingProductContainer from '../../group-buying/list/product-container';
import TextTitle from '../sections/text-title';

import { getCollectionInitialProps } from 'hook/collection';
import { SiteSelect } from '../sections/site-select';
import { FormRadio } from '../sections/form-radio';
import { FormCheckbox } from '../sections/from-checkbox';
import { FormSelect } from '../sections/form-select';
import { FormText } from '../sections/form-text';
import { FormMulText } from '../sections/form-mulit-text';

export const SectionsMap = {
  'product-show-chocolate': {
    // component: loadable(() => import('../sections/chocolate')), // Chocolate,
    component: Chocolate,
  },
  'slideshow-video-pic': {
    // component: loadable(() => import('../sections/slide-video-pic')), // SlideVideoPic,
    component: SlideVideoPic,
  },
  'product-show-clothes': {
    // component: loadable(() => import('../sections/clothes')), // SlideVideoPic,
    component: Clothes,
  },
  'section-spacing': {
    // component: loadable(() => import('../sections/section-spacing')), // SectionSpacing,
    component: SectionSpacing,
  },
  'comprehensive-nav': {
    // component: loadable(() => import('../sections/section-spacing')), // SectionSpacing,
    component: ComprehensiveNav,
  },
  'featured-row': {
    // component: loadable(() => import('../sections/featured-row')), // FeaturedRow,
    component: FeaturedRow,
  },
  slideshow: {
    // component: loadable(() => import('../sections/slide-show')), // SlideShow,
    component: SlideShow,
  },
  'slideshow-v2': {
    // component: loadable(() => import('../sections/slide-show-v2')), // SlideShowV2,
    component: SlideShowV2,
  },
  'slideshow-v3': {
    // component: loadable(() => import('../sections/slide-show-v3')), // SlideShowV3,
    component: SlideShowV3,
  },
  'logo-bar': {
    // component: loadable(() => import('../sections/logo-bar')), // LogoBar,
    component: LogoBar,
  },
  hero: {
    // component: loadable(() => import('../sections/hero')), // Hero,
    component: Hero,
  },
  quotes: {
    // component: loadable(() => import('../sections/quotes')), // Quotes,
    component: Quotes,
  },
  'quotes-v2': {
    // component: loadable(() => import('../sections/quotes-v2')), // QuotesV2,
    component: QuotesV2,
  },
  collection: {
    // component: loadable(() => import('../sections/collection')),
    component: Collection,
    getInitialProps: getCollectionInitialProps,
  },
  'collection-v2': {
    // component: loadable(() => import('../sections/collection-v2')),
    component: CollectionV2,
    getInitialProps: getCollectionInitialProps,
  },
  'collection-v3': {
    // component: loadable(() => import('../sections/collection-v3')),
    component: CollectionV3,
    getInitialProps: getCollectionInitialProps,
  },
  'product-grid': {
    // component: loadable(() => import('../sections/product-grid')),
    component: ProductGrid,
    getInitialProps: getCollectionInitialProps,
  },
  'collection-list': {
    // component: loadable(() => import('../sections/collection-list')), // CollectionList,
    component: CollectionList,
  },
  'feature-columns': {
    // component: loadable(() => import('../sections/feature-columns')), // FeaturedColumns,
    component: FeaturedColumns,
  },
  'featured-content': {
    // component: loadable(() => import('../sections/featured-content')), // FeaturedContent,
    component: FeaturedContent,
  },
  'image-bar': {
    // component: loadable(() => import('../sections/image-bar')), // ImageBar,
    component: ImageBar,
  },
  'featured-products': {
    // component: loadable(() => import('../sections/featured-products')),
    component: FeaturedProducts,
    getInitialProps: preFetchFeaturedProductsData,
  },
  'featured-grid': {
    // component: loadable(() => import('../sections/featured-grid')), // FeaturedGrid,
    component: FeaturedGrid,
  },
  'announcement-bar': {
    // component: loadable(() => import('../sections/announcement-bar')), // AnnouncementBar,
    component: AnnouncementBar,
  },
  'header-v2': {
    // component: loadable(() => import('../sections/header-v2')),
    component: HeaderV2,
    getInitialProps: preFetchHeaderV2Data,
  },
  footer: {
    // component: loadable(() => import('../sections/footer')), // Footer,
    component: Footer,
  },
  header: {
    // component: loadable(() => import('../sections/header')),
    component: Header,
    getInitialProps: preFetchHeaderData,
  },
  video: {
    // component: loadable(() => import('../sections/video')), // Video,
    component: Video,
  },
  newsletter: {
    // component: loadable(() => import('../sections/newsletter')), // Newsletter,
    component: Newsletter,
  },
  // 'product-template': {
  //     component: loadable(() => import('../../goods-detail')),
  //     getInitialProps: preFetchGoodsDetailDate,
  // },
  // 'collection-template': {
  //     component: loadable(() => import('../../goods-list')),
  //     getInitialProps: preFetchGoodsListData,
  // },
  'collection-cover-pic': {
    // component: loadable(() => import('../../goods-list/components/collection-cover-pic')), // CollectionCoverPic,
    component: CollectionCoverPic,
  },
  'product-recommendation': {
    // component: loadable(() => import('../../goods-detail/components/recommend-product')),
    component: RecommendProduct,
  },
  'simple-product': {
    component: SimpleProduct,
  },
  'site-select': {
    component: SiteSelect,
  },
  'featured-group-buying': {
    component: FeaturedGroupBuying,
  },
  'text-title': {
    component: TextTitle,
  },
  'featured-group-buying-products': {
    component: GroupBuyingProductContainer,
  },
  'form-radio': {
    component: FormRadio,
  },
  'form-checkbox': {
    component: FormCheckbox,
  },
  'form-select': {
    component: FormSelect,
  },
  'form-text': {
    component: FormText,
  },
  'form-mul-text': {
    component: FormMulText,
  },
};

export const globalConfigOfSectionMap = {
  'announcement-bar': {
    getValueAfterFormate: (theme) => {
      const { current = {} } = theme || {};
      const linkArr = [
        {
          link: 'social_twitter_link',
          icon: 'iconic_Twitter',
        },
        {
          link: 'social_facebook_link',
          icon: 'iconic_Facebook',
        },
        {
          link: 'social_pinterest_link',
          icon: 'iconic_Pinterest',
        },
        {
          link: 'social_instagram_link',
          icon: 'iconInstagram1',
        },
        {
          link: 'social_tumblr_link',
          icon: 'iconic_Tumblr',
        },
        {
          link: 'social_snapchat_link',
          icon: 'iconic_Snapchat',
        },
        {
          link: 'social_youtube_link',
          icon: 'iconic_YouTube',
        },
        {
          link: 'social_vimeo_link',
          icon: 'iconic_Vimeo',
        },
        { link: 'social_tiktok_link', icon: 'iconshejiaomeiti_TikTok' },
        { link: 'social_wechat_link', icon: 'iconshejiaomeiti_Wechat' },
        { link: 'social_whatsapp_link', icon: 'iconshejiaomeiti_Whatsapp' },
        { link: 'social_linkedin_link', icon: 'iconshejiaomeiti_LinkedIn' },
        { link: 'social_reddit_link', icon: 'iconreddit-square' },
      ];
      const linkObj = {
        social_twitter_link: 'iconic_Twitter',
        social_facebook_link: 'iconic_Facebook',
        social_pinterest_link: 'iconic_Pinterest',
        social_instagram_link: 'iconInstagram1',
        social_tumblr_link: 'iconic_Tumblr',
        social_snapchat_link: 'iconic_Snapchat',
        social_youtube_link: 'iconic_YouTube',
        social_vimeo_link: 'iconic_Vimeo',
        social_tiktok_link: 'iconshejiaomeiti_TikTok',
        social_wechat_link: 'iconshejiaomeiti_Wechat',
        social_whatsapp_link: 'iconshejiaomeiti_Whatsapp',
        social_linkedin_link: 'iconshejiaomeiti_LinkedIn',
        social_reddit_link: 'iconreddit-square',
      };
      const socialLinkArr = [];
      const socialLinksOrder = current?.social_links_order || [];
      const socialLinks = current?.social_links || {};
      linkArr.map((item) => {
        const { link, icon } = item;
        if (current[link]) {
          socialLinkArr.push({
            link: current[link],
            icon,
          });
        }
      });
      if (socialLinksOrder?.length) {
        // 社交媒体结构做了改造，需要兼容之前的数据，所以这里如果有新数据就显示新的，否则显示原来的
        const newSocialLinkArr = [];

        socialLinksOrder.forEach((linkId) => {
          const { settings = {}, type = '' } = socialLinks?.[linkId] || {};
          const { title = '' } = settings;
          if (title) {
            newSocialLinkArr.push({
              link: title,
              icon: linkObj?.[type],
            });
          }
        });
        return newSocialLinkArr;
      }

      return socialLinkArr;
    },
  },
  footer: {
    getValueAfterFormate: (theme) => {
      const { current = {} } = theme || {};
      const linkArr = [
        {
          link: 'social_twitter_link',
          icon: 'iconic_Twitter',
        },
        {
          link: 'social_facebook_link',
          icon: 'iconic_Facebook',
        },
        {
          link: 'social_pinterest_link',
          icon: 'iconic_Pinterest',
        },
        {
          link: 'social_instagram_link',
          icon: 'iconInstagram1',
        },
        {
          link: 'social_tumblr_link',
          icon: 'iconic_Tumblr',
        },
        {
          link: 'social_snapchat_link',
          icon: 'iconic_Snapchat',
        },
        {
          link: 'social_youtube_link',
          icon: 'iconic_YouTube',
        },
        {
          link: 'social_vimeo_link',
          icon: 'iconic_Vimeo',
        },
        { link: 'social_tiktok_link', icon: 'iconshejiaomeiti_TikTok' },
        { link: 'social_wechat_link', icon: 'iconshejiaomeiti_Wechat' },
        { link: 'social_whatsapp_link', icon: 'iconshejiaomeiti_Whatsapp' },
        { link: 'social_linkedin_link', icon: 'iconshejiaomeiti_LinkedIn' },
        { link: 'social_reddit_link', icon: 'iconreddit-square' },
      ];
      const linkObj = {
        social_twitter_link: 'iconic_Twitter',
        social_facebook_link: 'iconic_Facebook',
        social_pinterest_link: 'iconic_Pinterest',
        social_instagram_link: 'iconInstagram1',
        social_tumblr_link: 'iconic_Tumblr',
        social_snapchat_link: 'iconic_Snapchat',
        social_youtube_link: 'iconic_YouTube',
        social_vimeo_link: 'iconic_Vimeo',
        social_tiktok_link: 'iconshejiaomeiti_TikTok',
        social_wechat_link: 'iconshejiaomeiti_Wechat',
        social_whatsapp_link: 'iconshejiaomeiti_Whatsapp',
        social_linkedin_link: 'iconshejiaomeiti_LinkedIn',
        social_reddit_link: 'iconreddit-square',
      };
      const socialLinkArr = [];
      const socialLinksOrder = current?.social_links_order || [];
      const socialLinks = current?.social_links || {};
      linkArr.map((item) => {
        const { link, icon } = item;
        if (current[link]) {
          socialLinkArr.push({
            link: current[link],
            icon,
          });
        }
      });
      if (socialLinksOrder?.length) {
        // 社交媒体结构做了改造，需要兼容之前的数据，所以这里如果有新数据就显示新的，否则显示原来的
        const newSocialLinkArr = [];

        socialLinksOrder.forEach((linkId) => {
          const { settings = {}, type = '' } = socialLinks?.[linkId] || {};
          const { title = '' } = settings;
          if (title) {
            newSocialLinkArr.push({
              link: title,
              icon: linkObj?.[type],
            });
          }
        });
        return newSocialLinkArr;
      }
      return socialLinkArr;
    },
  },
};
