/**
 * 菜单相关的api接口封装
 */
import BaseApi from './base';

class ShareApi extends BaseApi {
  createPosterPicture(params) {
    return this._post('/api/posterController/createPosterPicture', params);
  }
}

export default new ShareApi();
