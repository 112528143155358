import React, { useEffect, useState, useRef } from 'react';
import { Form, FormStrategy, Validators } from 'zent';
import HtFormInput from 'components/standard/form-input';
import CodeInput from 'components/page/code-input';
import HtButton from 'components/standard/button';
import HtFormInputPhone from 'components/standard/form-input-phone';
import { STEP_TWO } from '../../const';
import { ACCOUNT_TYPE_MAP, SCENE_TYPE } from 'client/const/account';
import UserAccount from 'client/controllers/user-account';
import { SendSuccessTips } from './style';
import { isValidEmail } from 'helper/verify';
import emitter from '../../../../../event';
import { useManMachineVerify } from 'hook/account/use-man-machine-verify';

const Email_Key = 'verify_code_forget_pwd_email'
let mobileCountryCodeCache = ''

function FindPasswordByAccount(props) {
  const { setStep, intl, setStep1Info, withMobileLogin } = props;
  const propsRef = useRef()
  propsRef.current = props

  const accountTypes = withMobileLogin ? ACCOUNT_TYPE_MAP.phone : ACCOUNT_TYPE_MAP.email
  const [accountType, setAccountType] = useState(accountTypes);
  const form = Form.useForm(FormStrategy.View);
  const [mobile, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [emailSended, setEmailSended] = useState(false);

  useManMachineVerify({id: Email_Key})

  const [mobileCountryCode, setMobileCountryCode] = useState('');
  const mobileCountryCodeRef = useRef()
  mobileCountryCodeRef.current = ''

  const onMobileChange = (val) => {
    setMobile(val.trim());
  };
  const onEmailChange = (val) => {
    setEmail(val.trim());
  };
  const onMobileCountryCodeChange = (val) => {
    const v = val.trim()
    mobileCountryCodeCache = v
    mobileCountryCodeRef.current = v
    setMobileCountryCode(v);
  };
  const changeAccountType = () => {
    const type = accountType === ACCOUNT_TYPE_MAP.phone ? ACCOUNT_TYPE_MAP.email : ACCOUNT_TYPE_MAP.phone
    setAccountType(type);
  };
  const onSubmitEmail = (customI18nRecaptchaToken) => {
    const options = {
      header: {
        'custom-i18n-recaptcha-token': JSON.stringify(customI18nRecaptchaToken)
      }
    }
    const { email } = form.getValue();
    UserAccount.sendMailOnResetPwd({
      email,
    }, options).then(() => {
      setEmailSended(true);
    });
  };

  const onSubmitPhone = (customI18nRecaptchaToken) => {
    const { verifyCode, mobile } = form.getValue();
    const options = {
      header: {
        'custom-i18n-recaptcha-token': JSON.stringify(customI18nRecaptchaToken)
      }
    }
    const params = {
      mobileCountryCode: mobileCountryCodeCache,
      mobile,
      verifyCode,
    };
    UserAccount.checkMobileOnResetPwd(params, options).then(() => {
      setStep1Info(params);
      setStep(STEP_TWO);
    });
  };

  useEffect(() => {
    emitter.on('verify_code_forget_pwd_email', onSubmitEmail)
    return () => {
      mobileCountryCodeCache = ''
    }
  }, [])

  return (
    <Form layout='vertical' form={form}>
      {accountType === ACCOUNT_TYPE_MAP.email ? (
        <>
          <HtFormInput
            name='email'
            width='100%'
            placeholder={intl.formatMessage({
              defaultMessage: '邮箱',
              id: 'common_mailbox',
            })}
            onChange={onEmailChange}
            validators={[
              Validators.required(
                intl.formatMessage({
                  defaultMessage: '请输入邮箱',
                  id: 'please_input_your_email',
                }),
              ),
              isValidEmail(
                intl.formatMessage({
                  id: 'pleaseInputEmailByValid',
                  defaultMessage: '请输入正确的邮箱',
                }),
              ),
            ]}
          />
          {emailSended && (
            <SendSuccessTips>
              <div className='icon-wrapper'>
                <svg aria-hidden='true' width={34} height={34} fill='#409979'>
                  <use xlinkHref='#iconic-chenggong' />
                </svg>
              </div>
              <div className='tips-wrapper'>
                <span className='title'>
                  {intl.formatMessage({
                    defaultMessage: '电子邮件发送成功',
                    id: 'f4f8e48d9c30431a92702511884bafcf',
                  })}
                </span>
                <span className='tips'>
                  {intl.formatMessage({
                    defaultMessage: '我们已向您发送电子邮件，其中包含更新密码的链接',
                    id: 'account.recovery.reset_password_send_alert',
                  })}
                </span>
              </div>
            </SendSuccessTips>
          )}
        </>
      ) : (
        <>
          <HtFormInputPhone
            intl={intl}
            width='100%'
            form={form}
            onMobileChange={onMobileChange}
            onMobileCountryCodeChange={onMobileCountryCodeChange}
          />
          <CodeInput
            intl={intl}
            mobile={mobile}
            accountType={accountType}
            email={email}
            form={form}
            scene={SCENE_TYPE.reset_pwd}
            mobileCountryCode={mobileCountryCode}
            verifyCodeId='verify_code_forgot_pwd_phone'
          />
        </>
      )}

      <HtButton
        verifyCodeId={Email_Key}
        full
        size='large'
        type='primary'
        htmlType='submit'
        disabled={emailSended}
        hidden={accountType === ACCOUNT_TYPE_MAP.phone}
      >
        {intl.formatMessage({
          defaultMessage: '重置密码',
          id: 'account.recovery.reset_password',
        })}
      </HtButton>
    
      {accountType === ACCOUNT_TYPE_MAP.phone && (
        <HtButton
          full
          size='large'
          type='primary'
          htmlType='submit'
          disabled={emailSended}
          onClick={onSubmitPhone}
        >
          {intl.formatMessage({
            defaultMessage: '下一步',
            id: '38ce27d84639f3a6e07c00b3b4995c0e',
          })}
        </HtButton>
      )}

      {withMobileLogin && (
        <HtButton full plain onClick={changeAccountType}>
          {accountType === ACCOUNT_TYPE_MAP.email
            ? intl.formatMessage({
                defaultMessage: '手机号找回',
                id: '917714ea889f4c309abf07d04df75cfc',
              })
            : intl.formatMessage({
                defaultMessage: '邮箱找回',
                id: 'ef37ab8aed594887b23dfd19d8a9c563',
              })}
        </HtButton>
      )}
    </Form>
  );
}

export default FindPasswordByAccount;
