import styled from 'styled-components';

export const Style = styled.div`
  .goods-sku-detail {
    display: flex;
    flex-wrap: wrap;
    margin-top: ${(props) => props.theme.getSpace([8, 12])};
    .arco-tag {
      height: 38px;
      padding: 4px;
      border: 1px solid var(--color_text_field_border);
      line-height: 1;
      font-size: 14px;
      color: var(--color_body_text);
      border-radius: var(--border_radius_btn);
      img {
        height: 28px;
        width: 28px;
        border-radius: var(--border_radius_btn);
        display: block;
      }
      .tag-name {
        padding: 0 12px;
        white-space: normal;
      }
      &.tag-disabled {
        background: rgba(var(--color_text_field_border_rgb), 0.2);
        border-color: rgba(var(--color_text_field_border_rgb), 0.4);
        color: rgba(var(--color_text_field_text_rgb), 0.2);
        cursor: not-allowed;
      }
      &:not(.tag-disabled):hover {
        background: rgba(var(--color_button_rgb), 0.2);
      }
      &.arco-tag-checked {
        border: 1px solid var(--color_button);
        color: var(--color_button);
        background: rgba(var(--color_button_rgb), 0.1);
      }
    }
    .sku-box {
      border-radius: var(--border_radius_btn);
    }
  }
  .sku-wrapper {
    margin-top: ${(props) => props.theme.getSpace([16, 24])};
    text-align: left;
  }
  .square {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }
  .zent-radio-wrap,
  .graphic-item {
    margin-bottom: 8px !important;
  }
  .sku-select-list-wrapper {
    position: relative;
    .zent-select-text:after {
      display: none;
    }
    .hunt-select-switch {
      width: 30px;
      height: 100%;
      display: inline-flex;
      align-items: center;
      cursor: pointer;
      line-height: 46px;
      text-align: center;
      position: absolute;
      right: 0;
      top: 0;
      padding-right: 10px;
      .icon-switch {
        fill: var(--color_text_field_text, #fff);
      }
      &.hunt-select-switch-is-open-true {
        .icon-switch {
          transition: transform 0.1s linear;
          transform: rotate(180deg);
        }
      }
    }
  }

  /** 颜色 */
  .color {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-left: 4px;
    .color-item {
      align-items: center;
      display: flex;
      justify-content: center;
      font-size: 0;
      margin: 0 16px 8px 0;
      position: relative;
      &:before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        border: 1px solid transparent;
        position: absolute;
        border-radius: 50%;
        padding: 2px;
        transition: all 0.3s;
      }
      &.active:before {
        border: 1px solid var(--color_button);
      }
      &.disabled {
        cursor: not-allowed;
        .color-inner {
          opacity: 0.2;
        }
        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          background: #fff;
          transform: rotate(135deg);
          transform-origin: center;
        }
      }
      .color-item-inner {
        width: calc(var(--pm-24-18) - 4px);
        height: calc(var(--pm-24-18) - 4px);
        overflow: hidden;
        border-radius: 50%;
        border: 1px solid rgba(var(--color_body_text_rgb), 0.2);
        &.color-item-inner-length-2 {
          .color-inner {
            width: 50%;
            height: calc(var(--pm-24-18) - 4px);
          }
        }
        .color-inner {
          display: inline-block;
          width: calc(var(--pm-24-18) - 4px);
          height: calc(var(--pm-24-18) - 4px);
        }
      }
    }
  }
  /**图文 */
  .graphic-type {
    display: flex;
    flex-wrap: wrap;
  }

  .select-type {
    &.zent-select {
      .zent-select-text {
        padding-top: ${(props) => props.theme.getSpace([4, 10])} !important;
        padding-bottom: ${(props) => props.theme.getSpace([4, 10])} !important;
        .zent-select-placeholder {
          color: rgba(var(--color_text_field_text_rgb), 0.4);
        }
      }
    }
  }

  .zent-radio-group {
    .zent-radio-inner {
      background-color: transparent;
    }
  }

  @media screen and (max-width: 750px) {
    .goods-sku-detail {
      .arco-tag {
        min-height: 28px;
        height: auto;
        padding: 2px;
        img {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
`;
