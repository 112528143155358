import React, { useMemo } from 'react';
import { NumberInput } from 'zent';
import cn from 'classnames';
import {
  GoodsTitle,
  MultipleSpecifications,
  ImageBox,
  PriceItem,
  DiscountInfo,
  DeleteBtn,
} from 'cpn/goods-item/goods-base';
import { HtGoodsPriceWithDiscount2 } from 'cpn/goods-price';
import Style from './style';
import { getUnitDiscount } from 'helper/discount';
import { useIntl } from 'react-intl';

export const CartItemMobile = (props) => {
  const {
    showDiscount = true,
    showDelete = true,
    isSidebar = true,
    item,
    index,
    onDelete,
    onChangeNum,
    currency,
    discountCode,
    disabledChangeNum = false,
    canEdit = true,
    canDelete = true,
    maxLimit,
    updateCartList,
    onToggleSelect,
    isExpiredGoodsStatus,
    onCartSkuChange,
    isMobile = false,
    initialData,
  } = props;

  const {
    imageUrl = '',
    title = '',
    sku = [],
    optionValues = [],
    price = 0,
    quantity = 0,
    inventory,
    variantId,
    goodsId,
    giftGoodsFlag,
    discountAmount = 0,
    hasSelected,
    memberGoodsFlag,
    memberLineItemInfoVO = {},
    compareAtPrice,
  } = item || {};

  const intl = useIntl();

  return (
    <Style className={cn('cart-item-section', { 'cart-sidebar-item': isSidebar })}>
      <ImageBox
        className='cart-goods-item-image'
        imageUrl={imageUrl}
        isExpiredGoodsStatus={isExpiredGoodsStatus}
        quantity={item.goodsStatus === 'sell_out' || item.goodsStatus === 'fully_sell_out' ? 0 : quantity}
        isSidebar={isSidebar}
        handle={title}
      />
      <div className='cart-goods-item-info'>
        <div>
          <GoodsTitle title={title} lineClamp={1} />
          <div className='cart-goods-item-info-price'>
            {memberGoodsFlag ? (
              <HtGoodsPriceWithDiscount2
                price={memberLineItemInfoVO?.price}
                compareAtPrice={price}
                initialData={initialData}
                direction='row'
                align='center'
              />
            ) : (
              <PriceItem
                price={price}
                // discount={unitDiscount}
                currency={currency}
                className='mt10 cart-price-item'
                isShowCompareAtPrice
                compareAtPrice={compareAtPrice}
              />
            )}
          </div>
          <MultipleSpecifications
            optionTitles={sku}
            optionValues={optionValues}
            className='mt8'
            canChangeSku
            goodsId={goodsId}
            preVariantId={variantId}
            updateCartList={updateCartList}
            canDelete={canDelete}
            isSidebar={isSidebar}
            onCartSkuChange={onCartSkuChange}
            type='leakage'
          />
          {!!discountAmount && showDiscount && (
            <DiscountInfo discount={discountAmount} currency={currency} discountCode={discountCode} className='mt10' />
          )}
        </div>
        <div className='flex-item-bottom'>
          {canEdit ? (
            <NumberInput
              className={cn(`number-input-disabled-${disabledChangeNum || item.goodsStatus !== 'ok'}`, 'number-input')}
              value={quantity}
              // max={maxCount}
              min={1}
              showCounter
              disabled={disabledChangeNum || item.goodsStatus !== 'ok'}
              onChange={(count) => {
                item.goodsStatus === 'ok' && onChangeNum(item, count);
              }}
            />
          ) : (
            <div>X{quantity}</div>
          )}

          {showDelete && (
            <div className='delete-wrapper'>
              <DeleteBtn
                onDelete={() => {
                  onDelete(item, index);
                }}
                isSidebar={isSidebar}
              />
              {!isSidebar && (
                <DeleteBtn
                  onDelete={() => {
                    onDelete(item, index);
                  }}
                  toWish
                />
              )}
            </div>
          )}
        </div>
        {isExpiredGoodsStatus && !isSidebar && (
          <div className='flex-item-bottom'>
            <span>
              {intl.formatMessage({
                id: 'ccf866b1b3054e7cb0d31af077fc5f60',
                defaultMessage: '商品已不能购买',
              })}
            </span>
            {showDelete && (
              <div className='delete-wrapper'>
                <DeleteBtn
                  onDelete={() => {
                    onDelete(item, index);
                  }}
                  isSidebar={isSidebar}
                />
                {!isSidebar && (
                  <DeleteBtn
                    onDelete={() => {
                      onDelete(item, index);
                    }}
                    toWish
                  />
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </Style>
  );
};
