/**
 * 购物车api接口封装
 */
import BaseApi from './base';
import { getCartUniqueId, requestStatus } from 'helper/cart';

const getPrefixPath = (name) => {
  const prefixPath = '/api/soa/com.youzan.i18n.cart.controller.web.ShoppingCartController/';
  return prefixPath + name;
};

// 购物车接口暂时只有客户端使用，如果需要服务端调需要修改

class CartApi extends BaseApi {
  // 对购物车的方法进行拦截
  _post(url, params = {}, options = {}) {
    if (options.notVerifyRequestStatus || requestStatus()) {
      return this._requestByJson(
        url,
        { uniqueId: getCartUniqueId(), ...params },
        {
          ...options,
          method: 'POST',
        },
      );
    }
    return new Promise((resolve) => {
      resolve({});
    });
  }

  addGoods(params) {
    return this._post(getPrefixPath('addGoods'), params, {
      notVerifyRequestStatus: true,
    });
  }

  deleteGoods(params) {
    return this._post(getPrefixPath('deleteGoods'), params);
  }

  getCartGoods(params) {
    return this._post(getPrefixPath('getCartGoods'), params, {
      throttle: true,
    });
  }

  mergeCart(params) {
    return this._post(getPrefixPath('mergeCart'), params);
  }

  updateVariant(params) {
    return this._post(getPrefixPath('updateVariant'), params);
  }

  getCartCount(params) {
    return this._post(getPrefixPath('getCartCount'), params, { throttle: true });
  }

  deleteAllGoods(params) {
    return this._post(getPrefixPath('deleteAllGoods'), params);
  }

  updateGoodsNum(params) {
    return this._post(getPrefixPath('updateGoodsNum'), params);
  }
}

export default new CartApi();
