import cartApi from 'api/cart';
import orderApi from 'api/order';
import { CART_UNIQUE_ID_SIGN, CART_LIST_CHANGE_EVENT, CART_COUNT_CHANGE_EVENT } from 'const/cart';
import { globalFormatMessage } from 'utils/lang';
import { Notify } from 'zent';
import { getFormatMultipleAutoDiscountsCart } from 'hook/cart/helper';

// import store from 'store';

// 为了读写存储保持和极致移动的行为一致
export const getStorage = (key = CART_UNIQUE_ID_SIGN) => {
  const cartUniqueIdInfo = localStorage.getItem(key) ?? '';
  if (cartUniqueIdInfo) {
    try {
      return JSON.parse(cartUniqueIdInfo)?.data?.value;
    } catch (error) {
      console.error(error);
    }
  }
  return cartUniqueIdInfo;
};

export const setStorage = (value, key = CART_UNIQUE_ID_SIGN) => {
  const kdtId = window?.global_data?.kdtId;
  const data = {
    data: {
      expires: -1,
      kdtId,
      setTime: +new Date(),
      value,
    },
  };
  localStorage.setItem(key, JSON.stringify(data));
};
export const removeStorage = (key = CART_UNIQUE_ID_SIGN) => {
  localStorage.removeItem(key);
};

export const notifyCartCountChange = () => {
  const cartCountChange = new Event(CART_COUNT_CHANGE_EVENT);
  window.dispatchEvent(cartCountChange);
};

export const notifyCartListChange = () => {
  const cartListChangeEvent = new Event(CART_LIST_CHANGE_EVENT);
  window.dispatchEvent(cartListChangeEvent);
};

export const getCartUniqueId = () => {
  const cartUniqueId = getStorage(CART_UNIQUE_ID_SIGN);
  return cartUniqueId;
};

export const requestStatus = () => {
  const isLogin = !!window?.global_data?.userInfo?.customerId;
  return !!getCartUniqueId() || isLogin;
};

// 负数取0
export const dealCartItemQuantity = (lineItem) => {
  lineItem.quantity = lineItem.quantity <= 0 ? 0 : lineItem.quantity
  lineItem.inventory = lineItem.inventory <= 0 ? 0 : lineItem.inventory
}

const formatCartList = (list) => {
  return list.map((item) => {
    dealCartItemQuantity(item)
    return {
      ...item,
      isChecked: false,
    };
  });
};

export const addGoods = async (params) => {
  const isLogin = !!window?.global_data?.userInfo?.customerId;
  const { uniqueId } = await cartApi.addGoods({ ...params });
  if (uniqueId && !getCartUniqueId()) {
    setStorage(uniqueId);
  }
  notifyCartListChange();
  notifyCartCountChange();
};

export const getCartGoods = async () => {
  if (!requestStatus()) {
    return [];
  }
  const { items, uniqueId } = await cartApi.getCartGoods({});
  if (uniqueId && !getCartUniqueId()) {
    setStorage(uniqueId);
  }
  const list = formatCartList(items);
  return list;
};

export const refreshCartGoods = async () => {
  if (!requestStatus()) {
    return [];
  }
  const { items } = await cartApi.getCartGoods({});
  const list = formatCartList(items);
  notifyCartListChange();
  return list;
};

export const deleteGoods = async (params) => {
  await cartApi.deleteGoods({ ...params });
};

export const mergeCart = async () => {
  if (getCartUniqueId()) {
    const { isOver, uniqueId } = await cartApi.mergeCart({});
    if (uniqueId) {
      setStorage(uniqueId);
    }
    if (isOver) {
      Notify.info(
        globalFormatMessage({
          id: '376fa54d45fa4d5b9be2f77b6384fedc',
          defaultMessage: '购物车合并已超过最大数量',
        }),
      );
    }
    refreshCartGoods();
  }
};

export const calculateCart = async ({ goodsList = [], customerId = '' }) => {
  if (goodsList.length === 0) return {};
  const params = { lineItems: goodsList };
  if (customerId) {
    params.customerId = customerId;
  }

  // 算价 获取新商品
  const res = await orderApi.calculateCart(params);
  const { applyDiscountInfos, joinDiscountInfos, lineItems, totalPrice } = res;

  lineItems?.forEach(item => {
    dealCartItemQuantity(item)
  })

  const multipleAutoDiscountsCart = getFormatMultipleAutoDiscountsCart(
    applyDiscountInfos,
    joinDiscountInfos,
    lineItems,
  );

  return {
    multipleAutoDiscountsCart,
    applyDiscountInfos,
    joinDiscountInfos,
    totalPrice,
    lineItems,
  };
};
