import { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import LocalStorage from 'utils/local-storage';
import { intlMessage } from 'utils/intl/formate';
import { GOODS_BUY_ONCE, GROUP_BUYING } from 'utils/local-storage/config';
import { getSkuInfo, getGoodsSkuUrl } from 'helper/goods';
import { buriedPoint } from 'utils/log-buried-point';
import { pushToHistory } from 'utils/web-tool';
import Toast from 'cpn/toast';
import { useTips } from './notify';
import { HEAD_ICON_TYPE } from 'const';
import * as cartHelper from 'helper/cart';

/** 购物相关 */
export const useCart = ({ goodsDetail, history, goodsId }) => {
  const intl = useIntl();
  const defaultCart = {
    goodsId: goodsDetail?.goodsId,
    title: goodsDetail?.title || '',
    imageUrl: '',
    variantId: '', // sku id
    sku: [],
    price: 0,
    quantity: 1,
    priceSymbol: '$',
    requiresTax: 0, // 是否需要税收
  };
  const [cart, setCart] = useState(defaultCart);
  // const [cartList, setCartList] = useState(defaultCart);
  const { showSuccessTip } = useTips();

  // 顾客选购商品的个数
  const onNumChange = (value) => {
    if (Number(value) < 0) return;
    setCart({
      ...cart,
      quantity: Number(value),
    });
  };

  const isHaveGoods = () => {
    if (!cart.variantId) {
      Toast.info(intlMessage(intl, 'goods.sku.empty'), 800);
      return false;
    }
    return true;
  };

  /**
   * 立即购买
   *  1.校验参数
   *  2.更新数据
   *  3.数量置为1
   *  4.跳转页面
   *  5.埋点
   */
  const buySingleGoods = (type, orderParams = {}) => {
    if (type !== 'pre_paypal_listen' && !isHaveGoods()) return;
    if (type === 'pre_paypal' || type === 'pre_paypal_listen') {
      return [cart];
    }
    LocalStorage.set(GOODS_BUY_ONCE, [cart], -1);
    onNumChange(1);
    pushToHistory(history, '/order/subscribe', {
      step: 'info',
      refer: GOODS_BUY_ONCE,
      ...orderParams,
    });
    // 判断是分组
    if (window.global_data.collectionId) {
      buriedPoint('category_buynow', {
        source: '',
        ...cart,
        goods_id: goodsId,
        goodsType: goodsDetail?.goodsType,
      });
    }
    buriedPoint(
      'buy_now',
      {
        source: {
          cartList: [cart],
        },
        ...cart,
        goods_id: cart.goodsId,
        goodsType: goodsDetail?.goodsType,
      },
      {
        name: '立即购买',
        eventType: 'click',
      },
    );
  };

  const setCartList = async () => {
    const { giftGoodsFlag, goodsId, variantId, quantity = 1 } = cart;
    await cartHelper.addGoods({
      giftGoodsFlag,
      goodsId,
      quantity,
      variantId,
    });
    // const newList = await cartHelper.refreshCartGoods();
    // let cartList = LocalStorage.get(SHOP_CART) || [];
    // const index = cartList.findIndex((item) => item.variantId === cart.variantId);
    // if (index !== -1) {
    //     cartList[index].quantity = Number(cartList[index].quantity) + Number(cart?.quantity);
    // } else {
    //     cartList = [cart, ...cartList];
    // }
    // LocalStorage.set(SHOP_CART, cartList, -1);
    // onNumChange(quantity);
    showSuccessTip({
      text: intl.formatMessage({ defaultMessage: '已添加至购物车', id: 'has.add.car' }),
      targetId: HEAD_ICON_TYPE.cart,
    });
    buriedPoint(
      'add_cart',
      {
        source: '',
        goods_id: cart?.goodsId,
        ...cart,
        sku: [cart?.variantId],
        goodsType: goodsDetail?.goodsType,
        quantity,
      },
      {
        name: '添加购物车',
        eventType: 'click',
      },
    );
  };

  /**
   * 添加购物车
   */
  const addCart = () => {
    if (!isHaveGoods()) return;
    setCartList();
  };

  /** 点击拼团购买 */
  const onGroupBuying = (orderParams = {}) => {
    if (!isHaveGoods()) return;
    LocalStorage.set(GROUP_BUYING, [cart], -1);
    pushToHistory(history, '/order/subscribe', {
      step: 'info',
      refer: GROUP_BUYING,
      ...orderParams,
    });
  };

  const onSkuSelect = (item, index) => {
    const newSku = cart.sku;
    const totalSkuCount = goodsDetail?.options?.length
    let selectedSkuList = cart.selectedSkuList || [];
    if(selectedSkuList[index]?.title === item?.title) {
      selectedSkuList[index] = undefined;
    }else {
      selectedSkuList[index] = item;
    }
    selectedSkuList = selectedSkuList.filter((i) => !!i);
    cart.compareAtPrice = null;
    if(newSku[index] === item?.title) {
      newSku[index] = undefined;

    }else {
      newSku[index] = item?.title;
    }
    const selectSku = newSku.filter((i) => !!i);
    const isSelectedComplete = selectSku?.length === totalSkuCount; // 是否选择了完整的规格
    const curNum = isSelectedComplete ? 1 : cart.quantity; // 如果选中了指定的SKU，则把购买数量先置为1

    console.log('666_selectedSkuList', selectedSkuList);
    const goodsSkuUrl = getGoodsSkuUrl({ item, cart, selectedSkuList, goodsDetail });

    if (isSelectedComplete) {
      setCart({
        ...getSkuInfo({ sku: newSku, goodsDetail }),
        quantity: curNum,
        sku: newSku,
        selectedSkuList,
        goodsId: goodsDetail?.goodsId,
        goodsSkuUrl,
        isSelectedComplete,
      });
    } else {
      setCart({
        ...cart,
        ...getSkuInfo({ sku: newSku, goodsDetail }),
        quantity: curNum,
        sku: newSku,
        selectedSkuList,
        goodsId: goodsDetail?.goodsId,
        goodsSkuUrl,
        isSelectedComplete,
      });
    }
  };

  useEffect(() => {
    if (!goodsDetail && !goodsId) return;

    const { withVariants } = goodsDetail || {};
    if (!withVariants) {
      setCart({
        ...cart,
        goodsId: goodsDetail?.goodsId,
        ...getSkuInfo({ goodsDetail, sku: [] }),
      });
    }
  }, [goodsDetail]);

  return {
    cart,
    setCart,
    maxCartNum: cart?.variantId ? cart?.inventory : Infinity, // 可选择的商品最大数量，因为很多地方用就返回一下
    onNumChange,
    buySingleGoods,
    addCart,
    onSkuSelect,
    onGroupBuying,
  };
};
