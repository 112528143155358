import { getQueryString, safeJsonParse, safeJsonStringify } from '../index';
import { UTM_KEYS_MAP } from 'const/index';
import { UTM_INFO_COOKIE_EXPIRE } from '../../../constant';
import assign from 'lodash/assign';
import isEmpty from 'lodash/isEmpty';
import Fingerprint2 from 'fingerprintjs2';
import { setCookie, getCookie } from '../cookie';

// 流量来源统计处理
const sourceEnum = {
  search: 'search',
  social: 'social',
  email: 'email',
};

const sourceMap = {
  facebook: sourceEnum.social,
  twitter: sourceEnum.social,
  pinterest: sourceEnum.social,
  email: sourceEnum.email,
};

const getSearchReferrer = () => {
  const searchReg = /www.baidu.com|www.google|global.bing.com|yahoo.com｜yandex.com|duckduckgo.com/g;
  const referrer = document?.referrer || '';
  return referrer.match(searchReg);
};

export const getSourceObj = () => {
  const searchReferrer = getSearchReferrer();

  const query = getQueryString(window.location.href) || {};
  const { fbclid: fbClId, source } = query;

  if (searchReferrer) {
    return {
      source: sourceEnum.search,
      source_desc: searchReferrer,
    };
  }

  if (fbClId) {
    return {
      source: sourceEnum.social,
      source_desc: 'facebook',
    };
  }

  if (source) {
    return {
      source: sourceMap[source] || source,
      source_desc: source,
    };
  }

  return {
    source: '',
    source_desc: '',
  };
};

/**
 * 获取浏览器指纹信息，挂载到构造函数的browserId上
 * @param classConstruction 构造函数
 * @returns void 0
 */
export const getBrowserId = (classConstruction) => {
  // eslint-disable-next-line no-undef
  if (__isServer) return;
  Fingerprint2.get((components) => {
    const values = components.map((component, index) => {
      // 把微信浏览器里UA的wifi或4G等网络替换成空,不然切换网络会ID不一样
      return index === 0 ? component.value.replace(/\bNetType\/\w+\b/, '') : component.value;
    });
    classConstruction.browserId = Fingerprint2.x64hash128(values.join(''), 31);
  });
};

export const getBrowser = () => {
  const { userAgent } = window.navigator; // 取得浏览器的userAgent字符串
  const isOpera = userAgent.indexOf('Opera') > -1; // 判断是否Opera浏览器
  const isIE = userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1 && !isOpera; // 判断是否IE浏览器
  const isEdge = userAgent.indexOf('Edge') > -1; // 判断是否IE的Edge浏览器
  const isFF = userAgent.indexOf('Firefox') > -1; // 判断是否Firefox浏览器
  const isSafari = userAgent.indexOf('Safari') > -1 && userAgent.indexOf('Chrome') === -1; // 判断是否Safari浏览器
  const isChrome = userAgent.indexOf('Chrome') > -1 && userAgent.indexOf('Safari') > -1; // 判断Chrome浏览器

  if (isIE) {
    const reIE = new RegExp('MSIE (\\d+\\.\\d+);');
    reIE.test(userAgent);
    const fIEVersion = parseFloat(RegExp.$1);
    if (fIEVersion === 7) {
      return 'IE7';
    } else if (fIEVersion === 8) {
      return 'IE8';
    } else if (fIEVersion === 9) {
      return 'IE9';
    } else if (fIEVersion === 10) {
      return 'IE10';
    } else if (fIEVersion === 11) {
      return 'IE11';
    } else {
      return '0';
    }
  }
  if (isOpera) {
    return 'Opera';
  }
  if (isEdge) {
    return 'Edge';
  }
  if (isFF) {
    return 'FF';
  }
  if (isSafari) {
    return 'Safari';
  }
  if (isChrome) {
    return 'Chrome';
  }
};

export const getUtmInfoFromQuery = () => {
  const query = getQueryString(window.location.href);
  const { source } = getSourceObj() || {};

  const queryUtmInfo = UTM_KEYS_MAP.reduce((result, key) => {
    /** 如果utm_source字段没有值，就把source字段赋值给它，source字段是最早做数据统计用的，这里的逻辑是为了让报表可以从source维度分析 */
    const utmItem = key === 'utm_source' ? query[key] || source : query[key];

    return utmItem ? assign(result, { [key]: utmItem }) : result;
  }, {});

  return isEmpty(queryUtmInfo) ? null : queryUtmInfo;
};

export const updateUtmInfoToCookie = () => {
  const queryUtmInfo = getUtmInfoFromQuery();

  if (!queryUtmInfo) {
    return;
  }

  setCookie('allvalue-c-utm', safeJsonStringify(queryUtmInfo), {
    expires: UTM_INFO_COOKIE_EXPIRE,
  });
};

export const assignDefaultUtmInfo = (utmInfo) => {
  const valueLen = Object.values(utmInfo || {}).filter((item) => item).length;

  if (!valueLen) {
    return {};
  }

  return UTM_KEYS_MAP.reduce((result, key) => {
    const utmItemValue = result[key] || '';

    return assign(result, { [key]: utmItemValue });
  }, utmInfo || {});
};

/** 获取埋点上传需要的utm参数, 优先使用url上的utm, 如果url上带有utm，更新cookie */
export const getLogUtmParams = () => {
  const queryUtmInfo = getUtmInfoFromQuery();
  const cookieUtmInfo = safeJsonParse(getCookie('allvalue-c-utm'));
  const utmInfo = queryUtmInfo || cookieUtmInfo || {};

  if (queryUtmInfo) {
    updateUtmInfoToCookie();
  }

  return assignDefaultUtmInfo(utmInfo);
};

export const jsonToUrl = (params) => {
  return Object.keys(params)
    .sort()
    .map((key) => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
    })
    .join('&');
};

const getTiktokParams = () => {
  const cacheTTAdInfo = window.sessionStorage.getItem('tt_adInfo');
  let ttclid = '';
  if (cacheTTAdInfo) {
    try {
      ttclid = JSON.parse(cacheTTAdInfo).callback;
    } catch (err) {
      console.log(err);
    }
  }
  if (ttclid) {
    return {
      ttclid,
    };
  }
  return {};
};

export const addUrlTrackInfo = () => {
  const utmParams = getLogUtmParams() ?? {};
  const tiktokParams = getTiktokParams();
  const params = { ...utmParams, ...tiktokParams };
  return isEmpty(params) ? '' : `&${jsonToUrl(params)}`;
};
