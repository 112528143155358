import styled from 'styled-components';
// import { getSizeVw, getPercentWidth } from '../../../style/fn';

export const SkuSelectStyle = styled.div`
  .operation-item {
    margin-top: 24px;
    width: 100%;
  }
`;

export const Style = styled.div`
  @media screen and (max-width: 750px) {
    .sku-container {
      width: 100%;
      box-sizing: border-box;
      position: fixed !important;
      bottom: 0;
      background: #fff;
      z-index: 20;
      left: 0;
    }

    .mobile-mask {
      top: 0;
      left: 0;
      display: block;
      position: fixed;
      width: 100vw;
      height: 100vh;
      z-index: 10;
      background: rgba(0, 0, 0, 0.5);
    }
  }

  @media screen and (min-width: 750px) {
    width: 100%;
    position: absolute;
    max-height: 400px;
    overflow: scroll;
    width: 100%;
    bottom: 60px;
    z-index: 10;
  }

  .price-sale .price-decimal {
    font-size: var(--base-font-16-14);
    color: var(--color_sale_text);
  }
  @keyframes init {
    from {
      bottom: -300px;
    }
    to {
      bottom: 0;
    }
  }
  .animate-sku {
    animation: init 0.5s;
  }
  .sku-container {
    background: #fff;
    position: relative;
    padding: 0 var(--pm-16-10) var(--pm-16-10);
    box-shadow: 0px 2px 24px 0px rgba(209, 213, 219, 0.4);
  }

  .sku-close-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .number-input-wrapper {
    margin-top: 12px;
    .zent-number-input {
      border-radius: var(--border_radius_btn) !important;
    }
  }

  .sku-block {
    background: #fff;
    cursor: pointer;
    padding-top: var(--pm-16-10);
  }

  .close-buy-now-icon {
    width: 24px;
    height: 24px;
    display: inline-block;
  }
`;
