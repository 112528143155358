import styled from 'styled-components';
import { getStylesBytextAlignment } from '../../../../style/fn';

export const Style = styled.div`
  .text-title-contaier {
    width: 100%;
    padding: 24px 0;
    box-sizing: border-box;
    ${(props) => getStylesBytextAlignment(props.textAlignment)}
    .text-title-content {
      display: inline-flex;
      flex-direction: column;
      align-items: ${(props) => (props.textAlignment == 'center center' ? 'center' : 'flex-start')};
      justify-content: center;
      .text-title-desc {
        display: inline-flex;
        justify-content: space-between;
        flex-direction: ${(props) => (props.textAlignment == 'center center' ? 'column' : 'row')};
      }
    }
    .line {
      width: 100%;
      height: 0;
      border-bottom: 1px solid ${(props) => props.colorDecorate};
    }
  }
  .more-btn {
    font-family: PingFang SC;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    .link-arrow {
      width: 6px;
      height: 6px;
      border-top: 1px solid;
      border-right: 1px solid;
      transform: rotate(45deg);

      display: inline-block;
      margin-left: 5px;
    }
  }

  .style_one,
  .style_four,
  .style_five {
    .text-title-content {
      width: 100%;
      .desc {
        width: 100%;
      }
    }
  }
  .style_two {
    .line {
      margin: 24px 0 0;
    }
    .text-title-content {
      width: 100%;
      .desc {
        width: 100%;
      }
    }
  }
  .style_three {
    .line {
      margin: 0 0 24px;
    }
    .line:last-child {
      margin: 24px 0 0;
    }
    .desc {
      display: inline-flex;
      justify-content: space-between;
      align-items: ${(props) => (props.textAlignment == 'center center' ? 'center' : 'flex-start')};
      flex-direction: column !important;
    }
  }
  .style_four {
    .title {
      display: flex;
      align-items: ${(props) => (props.textAlignment == 'center center' ? 'center' : 'flex-start')};
      flex-direction: ${(props) => (props.textAlignment == 'center center' ? 'column' : 'row')};
    }
    .decorate-left {
      height: ${(props) => props.decorateSize + 'px'};
      margin-right: 16px;
      border-left: 4px solid;
      border-color: ${(props) => props.colorDecorate};
      vertical-align: middle;
    }
    .decorate-bottom {
      width: ${(props) => props.decorateSize + 'px'};
      height: 16px;
      border-bottom: 4px solid;
      border-color: ${(props) => props.colorDecorate};
    }
    .decorate-1h {
      height: 58px;
      margin-top: 17px;
    }
    .decorate-2h {
      height: 40px;
      margin-top: 14px;
    }
    .decorate-3h {
      height: 36px;
      margin-top: 12px;
    }
    .decorate-4h {
      height: 30px;
      margin-top: 11px;
    }
    .decorate-5h {
      height: 24px;
      margin-top: 9px;
    }
    .decorate-6h {
      height: 22px;
      margin-top: 7px;
    }
    .decorate-7h {
      height: 20px;
      margin-top: 6px;
    }
    .decorate-8h {
      height: 16px;
      margin-top: 6px;
    }
    .decorate-9h {
      height: 14px;
      margin-top: 5px;
    }
    .decorate-10h {
      height: 12px;
      margin-top: 5px;
    }
    .decorate-11h {
      height: 10px;
      margin-top: 5px;
    }
    .decorate-12h {
      height: 10px;
      margin-top: 4px;
    }
  }
  .style_five {
    .title {
      display: flex;
      align-items: ${(props) => (props.textAlignment == 'center center' ? 'center' : 'flex-start')};
      flex-direction: ${(props) => (props.textAlignment == 'center center' ? 'column' : 'row')};
    }
    .decorate-left {
      width: ${(props) => props.decorateSize / 2 + 'px'};
      height: ${(props) => props.decorateSize / 2 + 'px'};
      border-radius: 50%;
      margin-right: 16px;
      background-color: ${(props) => props.colorDecorate};
      display: inline-block;
      vertical-align: middle;
    }
    .decorate-bottom {
      width: ${(props) => props.decorateSize / 2 + 'px'};
      height: ${(props) => props.decorateSize / 2 + 'px'};
      border-radius: 50%;
      margin-top: 16px;
      background-color: ${(props) => props.colorDecorate};
      display: inline-block;
    }
  }
  .style_six {
    .title {
      cursor: pointer;
    }
    .decorate {
      margin-left: 17px;
      font-weight: 900;
    }
  }
  .style_seven {
    .text-title-content {
      background-color: ${(props) => props.colorDecorate};
      border: 4px solid ${(props) => props.colorDecorateLine};
      box-sizing: border-box;
      padding: 36px;
      align-items: center;
      cursor: pointer;
      text-align: center;
      .desc {
        align-items: center;
        flex-direction: column;
      }
      .more-btn {
        display: block;
        width: 0;
        height: 0;
        overflow: hidden;
        margin: 31px 0 0;
        line-height: 0;
        font-size: 0;
        vertical-align: middle;
        border-right: 9px solid transparent;
        border-left: 9px solid transparent;
        border-top: 9px solid red;
        border-bottom: 9px solid transparent;
      }
    }
  }
`;
