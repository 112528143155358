import React from 'react';
import cn from 'classnames';
import min from 'lodash/min';
import { NumberInput, LayoutRow as Row, LayoutCol as Col } from 'zent';
import { SimpleGoodsInfo, PriceItem, DiscountInfo } from 'cpn/goods-item';
import { useIntl } from 'react-intl';
import { getUnitDiscount } from 'helper/discount';
import { HtGoodsPriceWithDiscount2 } from 'cpn/goods-price';

import { WarnTipsStyle, Style } from './style';

export const CartItemPC = (props) => {
  const {
    item,
    index,
    onDelete,
    onChangeNum,
    currency,
    discountCode,
    disabledChangeNum = false,
    canEdit = true,
    canDelete = true,
    maxLimit,
    updateCartList,
    onToggleSelect,
    isExpiredGoodsStatus,
    onCartSkuChange,
  } = props;

  const { price, quantity = 1, discountAmount = 0, inventory, memberGoodsFlag, memberLineItemInfoVO = {} } = item;

  const unitDiscount = getUnitDiscount(discountAmount, quantity);
  const totalAmount = memberGoodsFlag ? quantity * memberLineItemInfoVO?.price ?? 0 : quantity * price;
  const max = maxLimit ? min([maxLimit, inventory]) : inventory;
  const intl = useIntl();

  return (
    <Row className='cart-item-section'>
      <Col span={isExpiredGoodsStatus ? 20 : 12}>
        <div className='layout-body-item layout-goods-detail'>
          <SimpleGoodsInfo
            canDelete={canDelete}
            item={item}
            showSelected={false}
            currency={currency}
            onDelete={onDelete}
            index={index}
            onChange={onChangeNum}
            updateCartList={updateCartList}
            onToggleSelect={onToggleSelect}
            inventory={inventory} // 这里其实是库存，以前命名太乱了，醉了啊
            isExpiredGoodsStatus={
              isExpiredGoodsStatus || !['ok', 'sell_out', 'fully_sell_out', undefined].includes(item.goodsStatus)
            }
            onCartSkuChange={onCartSkuChange}
          />
        </div>
      </Col>
      {!isExpiredGoodsStatus && (
        <Col span={4}>
          <div className='layout-body-item'>
            {memberGoodsFlag ? (
              <HtGoodsPriceWithDiscount2 price={memberLineItemInfoVO?.price} compareAtPrice={price} />
            ) : (
              <PriceItem
                type='column'
                currency={currency}
                price={price}
                discount={unitDiscount}
                hasSelected={item?.hasSelected}
                giftGoodsFlag={item?.giftGoodsFlag}
                priceStyle={{ fontSize: '18px' }}
              />
            )}
          </div>
        </Col>
      )}
      {!isExpiredGoodsStatus && (
        <Col span={4}>
          <div className='layout-body-item'>
            {canEdit ? (
              <>
                {item?.isShowMaxTips && !item?.giftGoodsFlag && max > 0 && (
                  <span className='max-tips'>
                    {intl.formatMessage({
                      defaultMessage: '已达最大可购买量',
                      id: '371ee7500ae4419fb057d6bdd77c5f9f',
                    })}
                  </span>
                )}
                <Style>
                  <NumberInput
                    className={cn(
                      `number-input-disabled-${disabledChangeNum || item.goodsStatus !== 'ok'}`,
                      'custom-number-input',
                    )}
                    value={item.quantity}
                    max={max}
                    min={1}
                    onChange={(count) => {
                      // eslint-disable-next-line no-unused-expressions
                      item.goodsStatus === 'ok' && onChangeNum(item, count);
                    }}
                    disabled={disabledChangeNum || item.goodsStatus !== 'ok'}
                    showCounter
                  />
                </Style>
              </>
            ) : (
              <div>X{item.quantity}</div>
            )}
            {inventory < 20 && !item?.giftGoodsFlag && (
              <WarnTipsStyle>
                <span className={cn({ 'zero-warn': inventory === 0 }, 'less-warn')}>
                  {intl.formatMessage({
                    defaultMessage: '库存剩余',
                    id: '63b51eb8183849ac9f722c0342d6cd7c',
                  })}
                  {` `}
                  {inventory}
                </span>
              </WarnTipsStyle>
            )}
          </div>
        </Col>
      )}

      <Col span={4}>
        <div className='layout-body-item right' style={{ textAlign: 'right' }}>
          {!isExpiredGoodsStatus && (
            <div className='price-part'>
              <PriceItem
                type='column'
                align='right'
                currency={currency}
                price={totalAmount}
                discount={discountAmount}
                isShowBeforePrice={false}
                priceStyle={{ fontSize: '18px' }}
              />
              <DiscountInfo
                className='padding-0'
                align='right'
                discount={discountAmount}
                currency={currency}
                discountCode={discountCode}
              />
            </div>
          )}
          {isExpiredGoodsStatus && (
            <div>
              {intl.formatMessage({
                defaultMessage: '已不能购买',
                id: '30d7e0dace094b87b3367881724b4d98',
              })}
            </div>
          )}
        </div>
      </Col>
    </Row>
  );
};
