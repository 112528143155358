import styled from 'styled-components';

export const MultipleSpecificationsStyle = styled.div`
  flex: 1;
  .mt16 {
    margin-top: 16px;
  }
  .mt10 {
    margin-top: 10px;
  }
  &.mt8 {
    margin-top: 8px;
  }
  .size {
    padding: 2px 8px;
    font-size: var(--base-font-14-12);
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-flex;
    align-items: center;
    word-break: break-word;
    max-width: 188px;
    cursor: pointer;
    background: rgba(var(--color_body_text_rgb), 0.04);
    color: rgba(var(--color_body_text_rgb), 0.6);
    &.size-show-border-false {
      border: none;
      /* padding-left: 0; */
    }
    .svg-wrapper {
      margin-left: 8px;
      fill: var(--color_body_text);
      fill-opacity: 0.6;
    }
    .size-detail {
      width: 120px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  &.leakage {
    .size {
      padding: 0;
      background: transparent;
      max-width: 100%;
      width: 100%;
    }
    .sku-line-options-values {
      /* white-space: nowrap; */
    }
  }
  .custom-sku-wrapper {
    border-radius: var(--border_radius_btn);
  }
`;

export const ImgContainerStyle = styled.div`
  width: 120px;
  height: 120px;
  justify-content: center;
  align-items: flex-start;
  display: flex;
  margin-right: 16px;
  position: relative;
  border-radius: var(--border_radius_pd_card);
  overflow: hidden;
  .img {
    object-fit: cover;
    max-width: 100%;
    max-height: 100%;
  }
  .image-content-wrapper {
    position: relative;
    width: 100%;
  }
  .sidebar-status {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.6);
    .tip-text {
      padding: 4px 8px;
      background: rgba(var(--color_body_text_rgb), 0.6);
      color: var(--color_card);
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      white-space: nowrap;
      max-width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .sold-out-tips {
    position: absolute;
    z-index: 1;
    width: 48px;
    text-align: center;
    height: 48px;
    background: rgba(0, 0, 0, 0.6);
    color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @media screen and (max-width: 750px) {
    width: 72px;
    height: 72px;
    margin-right: 12px;
    .sold-out-tips {
      &.is-sidebar {
        top: 12px;
      }
    }
    .img {
      object-fit: contain;
      max-width: 100%;
      max-height: 100%;
    }
  }
`;
export const GoodsTitleStyle = styled.div`
  font-size: var(--base-header-font-14-14);
  font-family: var(--font-stack-body);
  font-style: var(--font-style-body);
  position: relative;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => props.lineClamp};
  line-clamp: ${(props) => props.lineClamp};
  -webkit-box-orient: vertical;
  word-break: break-word;
  line-height: 20px;
`;

export const DeleteBtnStyle = styled.div`
  .delete-title {
    text-align: center;
    height: 71px;
    line-height: 71px;
  }
  .delete-content-wrapper {
    display: flex;
    height: 48px;
    align-items: center;
    color: #15161b;
    span {
      flex: 1;
      text-align: center;
      height: 48px;
      line-height: 48px;
    }
    .delete-text {
      color: #304ffe;
      border-left: solid 1px rgba(21, 22, 27, 0.08);
    }
  }
  .delete-icon {
    fill: rgba(var(--color_body_text_rgb), 0.6);
  }
  .pop-content-wrapper {
    padding: 12px;
    background-color: var(--color_card);
    width: 262px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.08);
    border-radius: 2px;
    .action-wrapper {
      text-align: right;
      margin-top: 10px;
      button {
        height: 34px !important;
        line-height: 34px !important;
      }
    }
  }
  #delete-model-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }
`;

export const DiscountInfoStyle = styled.div`
  border-radius: var(--border_radius_tag);
  overflow: hidden;
  .right {
    text-align: right;
    .price-detail {
      align-items: flex-end;
    }
  }
  .left {
    text-align: right;
  }
  display: inline-block;

  .discount-code-detail {
    display: inline-block;
    color: var(--color_activity_label);
    padding: 5px 0;
    &.is-set-bgColor {
      background: rgba(var(--color_activity_label_rgb), 0.1) !important;
      padding: 5px;
    }
    .discount-code-icon {
      margin-right: 2px;
      vertical-align: middle;
      fill: var(--color_activity_label);
      width: 16px;
      height: 16px;
    }
    .discount-code {
      max-width: 88%;
      font-size: 12px;
      font-weight: 600;
      line-height: 9px;
      color: var(--color_activity_label);
    }
  }
  .price-detail {
    font-weight: 500;
  }
`;

export const PriceItemStyle = styled.div`
  text-align: left;
  font-size: 14px;
  font-weight: bold;
  color: #1a1a1b;
  line-height: 14px;
  &.left {
    text-align: left;
  }
  &.right {
    text-align: right;
  }
  .price-detail {
    font-weight: 500;
    display: flex;
    .goods-info-discount-money {
      color: var(--color_body_text);
      font-size: 14px;
      font-weight: bold;
      line-height: 14px;
    }
    .origin-price {
      color: var(--color_body_text);
    }
  }

  &.column {
    .price-detail {
      flex-direction: column;
    }
    .goods-info-discount-money {
      margin-left: 0px;
      margin-top: 4px;
      margin-bottom: 4px;
    }
  }
  &.row {
    .goods-info-discount-money {
      margin-right: 8px;
    }
    .price-detail {
      align-items: center;
    }
  }
  &.row-reverse {
    .goods-info-discount-money {
      margin-left: 8px;
    }
    .price-detail {
      flex-direction: row-reverse;
    }
  }
  .origin-price {
    color: #333333;
  }
  .discount-price,
  .origin-price {
    &.is-discount,
    &.text-decoration {
      text-decoration: line-through;
      font-weight: 400;
      font-size: var(--font-14-12) !important;
      color: rgba(var(--color_body_text_rgb), 0.4) !important;
    }
    &.text-decoration {
      margin-left: 8px;
    }
  }
  @media screen and (max-width: 750px) {
    .goods-info-discount-money {
      line-height: 24px !important;
    }
  }
`;

export const TagStyle = styled.div`
  margin-top: 10px;
  color: var(--color_sale_text);
  background: rgba(var(--color_sale_text_rgb), 0.1);
  padding: 2px;
  font-size: 12px;
  display: inline-block;
  border-radius: 1px;
`;

export const SkuLineStyle = styled.div`
  display: inline-block;
  color: rgba(var(--color_body_text_rgb), 0.6);
  .options-value-item {
    display: inline-block;
    text-overflow: ellipsis;
    overflow: hidden;
    &:not(:last-child) {
      &:after {
        content: '/';
        color: var(--color_body_text);
        margin: 0 4px;
      }
    }
    .options-value-item-color {
      width: 12px;
      height: 12px;
      overflow: hidden;
      display: inline-block;
      border-radius: 50%;
      font-size: 0;
      .options-value-color-half {
        display: inline-block;
        height: 12px;
        width: 6px;
      }
      &.options-value-item-color-length-1 {
        .options-value-color-half {
          width: 12px;
        }
      }
    }
  }
`;
