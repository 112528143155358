/**
 * 发货相关的api接口封装
 */
import BaseApi from './base';

class DeliveryApi extends BaseApi {
  // 获取可发货地区信息
  getDeliveryZones(params) {
    return this._get('/api/soa/com.youzan.i18n.delivery.controller.web.DeliveryController/getDeliveryZones', params);
  }
  isExistRadiusLocalDelivery(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.localdelivery.controller.web.LocalDeliveryController/isExistRadiusLocalDelivery',
      params,
    );
  }
  // 获取自提点列表
  getGoodsPickupLocations(params) {
    return this._post(
      '/api/soa/com.youzan.i18n.pickup.controller.web.PickupLocationController/getGoodsPickupLocations',
      params,
    );
  }
  // 获取自提点详情
  getByLocationId(params) {
    return this._post(
      '/api/soa/com.youzan.i18n.pickup.controller.web.PickupLocationController/getByLocationId',
      params,
    );
  }
  // 获取自提人列表
  getPickUpInfoByCustomerId(params) {
    return this._post(
      '/api/soa/com.youzan.i18n.customer.controller.web.CustomerPickUpInfoController/getPickUpInfoByCustomerId',
      params,
    );
  }
  // 添加自提人
  createPickUpInfo(params) {
    return this._post(
      '/api/soa/com.youzan.i18n.customer.controller.web.CustomerPickUpInfoController/createPickUpInfo',
      params,
    );
  }
  // 编辑自提人
  updatePickUpInfo(params) {
    return this._post(
      '/api/soa/com.youzan.i18n.customer.controller.web.CustomerPickUpInfoController/updatePickUpInfo',
      params,
    );
  }
}

export default new DeliveryApi();
