import { useMemo } from 'react';
import { LIMIT_PERIOD, LIMIT_TYPE } from 'const/goods';
import { isEmpty, isNil } from 'lodash';

interface GoodsLimitProps {
  intl: any;
  // 商品库存
  inventory: number;
  // 赠品数量限制
  discountLimit?: number;
  limitInfo: {
    // 可购买数量
    availableQty: number;
    // 是否被限购
    limited: number;
    // 限购周期： 每天：day 每周：week 每月：month
    limitPeriod: LIMIT_PERIOD;
    // 限购原因
    limitReason: string;
    // 限额
    quota: number;
    // 数量限购类型： 周期限购：period 每单限购：order
    quantityLimitType: LIMIT_TYPE;
    // 已购数量
    usedQuota: number;
  };
}

export const useGoodsLimit = (props: GoodsLimitProps) => {
  const { limitInfo, intl, inventory, discountLimit } = props;

  // 限购提示
  const limitTips = useMemo(() => {
    if (isEmpty(limitInfo)) return null;
    // 商品是否有限制
    const { availableQty, quota, quantityLimitType } = limitInfo;
    let tips = '';
    if (quantityLimitType === LIMIT_TYPE.order) {
      tips = intl.formatMessage(
        {
          id: '9fec834c04bb4485991797065023653e',
          defaultMessage: '每单限购 {quota} 件',
        },
        {
          quota,
        },
      );
    }
    if (quantityLimitType === LIMIT_TYPE.period) {
      tips = intl.formatMessage(
        {
          id: 'c0e00f3d68454e4fb6b8d9b9f0ca7777',
          defaultMessage: '每天限购 {quota} 件，还可购买 {availableQty} 件',
        },
        {
          availableQty,
          quota,
        },
      );
    }
    return tips;
  }, [limitInfo]);

  // 最大数量
  const maxGoodsNum = useMemo(() => {
    // 赠品数量限制优先, -1 则没有限制
    if (!isNil(discountLimit) && discountLimit >= 0) return discountLimit;
    // 配置了商品限购
    if (!isEmpty(limitInfo)) {
      const { availableQty } = limitInfo;
      return Math.min(availableQty, inventory || 0);
    }
    return inventory;
  }, [limitInfo, discountLimit, inventory]);

  return {
    limitTips,
    maxGoodsNum,
  };
};
