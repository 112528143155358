// import { hot } from 'react-hot-loader/root';
import React, { useEffect, useState } from 'react';
import { initSdk } from './util/log-buried-point/ambush-log';
import { Switch, Route } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import GlobalStyle from './style/base';
import GlobalStyleAnimation from './style/animation';
import { MemberStatus } from 'const/member';
import Layout from './pages/design/layout';
import WhatsAppMessage from './util/sdk/whats-app-message';
import CmRobin from './util/sdk/cm-robin';
import sdkStart from './util/sdk/index';
import 'zent/css/index.css';
import 'swiper/swiper-bundle.css';
import 'react-photo-view/dist/index.css';
import { ThemeProvider } from 'styled-components';
import { useMemberStatus } from 'hook/member';
// import loadable from '@loadable/component';
import { useGlobalTheme, useClientWidth, GlobalTheme } from './hook/global-theme';
import emitter, { THEME_DATE_CHANGE } from './event';
import PopList from './pages/design/sections/pop-list';
import RootContext from './pages/context/root';
import { useDisableJs } from './hook/disable-js';
import InitModule from './components/base/init-module';
import LoginDialog, { LoginDialogInfoParams } from 'client/components/page/login-dialog';
import { changeGoogleLanguage } from 'helper/language';
import { loadThirdJavascript, THIRD_JAVASCRIPT_CONFIG } from './util/sdk/load-third-javascript';

// const PopList = loadable(() => import('./pages/design/sections/pop-list'));

/**
 * React Router v3 静态路由：静态路由是一堆在应用运行前就已经定义re好的路由配置，应用需要在启动时，加载这些配置，构建出整个应用的路由表，然后当接收到某一请求时，根据请求地址，到应用路由表中找到对应的处理页面或处理方法。
 * React Router v4 动态路由：Route不提供路由配置，而是一个普通的UI组件，不管请求的路径是什么，Route组件总是会被渲染，只不过在Route内部会判断请求路径是否与当前的path匹配，如果匹配，就会把Route component属性指向的组件作为子组件渲染出来，如果不匹配，会渲染一个null。
 * pathname:路径名
 * // 全局数据
 * // 组件需要的数据
 * initialData：数据
 * routeConfig：路由
 */

const App = ({ initialData, routeList, i18nLang, language }) => {
  // console.log('appinitialData', initialData);
  const { clientWidth } = useClientWidth();
  const { memberStatus } = useMemberStatus({
    kdtId: initialData?.kdtId,
    customerId: initialData?.userInfo?.customerId,
  });
  const locale = language;
  const [decorationTheme, setDecorationTheme] = useState();
  const [loginDialogInfo, setLoginDialogInfo] = useState<LoginDialogInfoParams>({
    visible: false,
  });

  useEffect(() => {
    const languageList = initialData.multiLanguageInfo?.languageList || [];
    const defaultLanguageInfo = languageList.find((item) => item.isPrimary === 1) || {};
    if (defaultLanguageInfo.language !== language) {
      // 默认语言不翻译
      changeGoogleLanguage(language);
    }
  }, [language, initialData]);

  useEffect(() => {
    emitter.on(THEME_DATE_CHANGE, (data) => {
      setDecorationTheme(data);
    });
  }, []);
  const theme: GlobalTheme = useGlobalTheme({
    clientWidth,
    themeData: decorationTheme || initialData?.themeData,
    userAgent: initialData.useAgent,
  });

  const loadThirdSdk = () => {
    sdkStart();
    /* eslint-disable no-new */
    new WhatsAppMessage(initialData?.kdtId);
    new CmRobin(initialData?.kdtId);
    loadThirdJavascript(THIRD_JAVASCRIPT_CONFIG.TCaptcha.name, THIRD_JAVASCRIPT_CONFIG.TCaptcha.url);
  };

  useEffect(() => {
    // eslint-disable-next-line no-undef
    if (__isServer) return;
    // 本地打包引入iconfont
    // eslint-disable-next-line global-require
    require('../../assets/iconfont');
    initSdk({ initialData });
    loadThirdSdk();
  }, []);

  useDisableJs();
  return (
    <IntlProvider locale={locale} messages={i18nLang.lang} defaultLocale='en'>
      <ThemeProvider theme={theme}>
        <RootContext.Provider
          value={{
            initialData,
            language,
            theme,
            member: {
              memberStatus,
              isMember: memberStatus?.status === MemberStatus.Done || memberStatus?.status === MemberStatus.Test,
            },
            setLoginDialogInfo,
            withMobileLogin: initialData.withMobileLogin,
          }}
        >
          <GlobalStyle />
          <GlobalStyleAnimation />
          <InitModule />
          <PopList themeData={initialData.themeData} locale={locale} initialData={initialData} />
          <LoginDialog
            visible={loginDialogInfo.visible}
            callbackUrl={loginDialogInfo.callbackUrl}
            language={language}
            withMobileLogin={initialData.withMobileLogin}
            onClose={() =>
              setLoginDialogInfo({
                visible: false,
              })
            }
            setLoginDialogInfo={setLoginDialogInfo}
            isFromAffiliate={loginDialogInfo.isFromAffiliate}
          />
          <Switch>
            {routeList.map((item) => {
              return (
                <Route
                  key={item.path}
                  exact
                  path={item.path}
                  render={(props) => {
                    const {
                      pageType,
                      name,
                      canNotEditLayout,
                      showFbMsg = false,
                      hideHeader = false,
                      hideFooter = false,
                      checkoutHeader = false,
                      isGreyBody = false,
                    } = item;
                    const newProps = {
                      ...item,
                      ...props,
                      initialData,
                      locale,
                      pageType,
                      name,
                      canNotEditLayout,
                      hideHeader,
                      hideFooter,
                      checkoutHeader,
                      showFbMsg,
                      isGreyBody,
                    };
                    const { layout = true, canDesignPage = false } = item;
                    if (canDesignPage) {
                      return <item.component {...newProps} />;
                    }

                    if (layout) {
                      return (
                        <Layout {...newProps}>
                          <item.component {...newProps} />
                        </Layout>
                      );
                    }

                    return <item.component {...newProps} />;
                  }}
                />
              );
            })}
          </Switch>
        </RootContext.Provider>
      </ThemeProvider>
    </IntlProvider>
  );
};

export default App;
