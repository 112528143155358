import styled from 'styled-components';

export const Style = styled.div`
  display: flex;
  margin-top: 12px;
  margin-bottom: 8px;
  .goods-detail-tag {
    font-size: 14px;
    line-height: 20px;
    position: relative;
    margin-right: 12px;
    display: flex;
    align-items: center;
    &:not(:last-child)::after {
      top: 4px;
      right: 0;
      width: 1px;
      content: '';
      opacity: 0.2;
      height: 12px;
      display: block;
      position: absolute;
      background: var(--color_body_text);
    }
  }
  .goods-tag-name {
    padding-left: 4px;
    padding-right: 12px;
    color: var(--color_body_text_rgb);
  }
  .goods-tag-icon {
    vertical-align: middle;
    fill: rgba(var(--color_body_text_rgb), 0.8);
  }
`;
