import loadable from '@loadable/component';

// 查看已有装修基础组件的demo页
// import DesignDemo from '../pages/design/demo';
import distributionRoute from './distribution';
import NotFindPage from '../pages/not-find-page';
import groupBuyingRoute from './group-buying';
import memberRoute from './member';
import authRoute from './auth';

import sectionConfig from '../pages/goods-detail/config';
import activityRoute from './activity';
import policy from '../pages/terms-of-use';
import callback from '../pages/callback-url';
import Custom from '../pages/custom/index';

export default [
  {
    component: loadable(() => import('../pages/design/home/index')),
    path: '/',
    exact: true,
    name: 'Layout',
    layout: false,
    pageType: '主页',
    showFbMsg: true,
    canNotEditLayout: true,
    cache: true,
  },
  {
    component: loadable(() => import('../pages/design/independent-design-frame')),
    path: '/independent-design-frame',
    exact: true,
    name: 'IndependentDesignFrame',
    layout: false,
    pageType: '主页',
    showFbMsg: false,
    canNotEditLayout: false,
    cache: false,
  },
  {
    component: loadable(() => import('../pages/activity/card-code')),
    path: '/discount/activity',
    exact: true,
    name: 'DiscountGoods',
    pageType: '活动商品列表',
  },
  {
    component: loadable(() => import('../pages/discount-goods/index')),
    path: '/discount/:discountType/goods',
    exact: true,
    name: 'DiscountGoods',
    pageType: '折扣商品列表',
  },
  {
    component: loadable(() => import('../pages/gift-goods/index')),
    path: '/gift/goods',
    exact: true,
    name: 'giftGoods',
    pageType: '赠品列表',
  },
  {
    component: loadable(() => import('../pages/components/decoration-wrapper/collection-template')),
    path: '/collections/all',
    exact: true,
    name: 'GoodsList',
    pageType: '所有商品',
    canNotEditLayout: true,
  },
  {
    component: loadable(() => import('../pages/components/decoration-wrapper/collection-template')),
    path: '/collections/:collectionId',
    exact: true,
    name: 'GoodsList',
    pageType: '商品分组页',
    canNotEditLayout: true,
    cache: false,
  },

  {
    path: '/search',
    name: 'Banana',
    exact: true,
    component: loadable(() => import('../pages/goods-search/index')),
    pageType: '商品搜索页',
  },
  {
    path: '/products/:id',
    name: 'product-template',
    canDesignPage: true,
    exact: true,
    component: loadable(() => import('../pages/goods-detail')),
    pageType: '商品详情页',
    showFbMsg: true,
    canNotEditLayout: true,
    cache: false,
    ...sectionConfig,
  },
  {
    path: '/order/subscribe',
    name: 'subscribe',
    exact: true,
    component: loadable(() => import('../pages/order/subscribe/index')),
    hideFooter: true,
    pageType: '下单页',
    hideHeader: false,
    checkoutHeader: true,
  },
  {
    path: '/order/complete',
    name: 'complete',
    exact: true,
    component: loadable(() => import('../pages/order/complete')),
    hideFooter: true,
    pageType: '下单页',
    hideHeader: false,
    checkoutHeader: true,
  },
  {
    path: '/order/invalid',
    name: 'invalid',
    exact: true,
    component: loadable(() => import('../pages/order/invalid')),
    hideFooter: true,
    pageType: '订单失效页',
    hideHeader: false,
    checkoutHeader: true,
  },
  {
    path: '/order/receipt/:receiptId',
    name: 'receipt',
    exact: true,
    component: loadable(() => import('../pages/order/receipt')),
    hideFooter: true,
    pageType: '收据页',
    hideHeader: false,
    checkoutHeader: true,
  },
  {
    path: '/shopping-cart',
    name: 'shoppingCart',
    exact: true,
    component: loadable(() => import('../pages/shopping-cart/index')),
    hideFooter: true,
    hideHeader: false,
    checkoutHeader: true,
    pageType: '购物车',
  },
  {
    path: '/pages/:id',
    name: 'custom',
    exact: true,
    component: Custom,
    showFbMsg: true,
    canNotEditLayout: true,
    pageType: '自定义页',
  },
  {
    path: '/me',
    name: 'me',
    exact: true,
    component: loadable(() => import('../pages/account/me/index')),
    isGreyBody: true,
    pageType: '用户主页',
  },
  {
    path: '/blogs',
    name: 'blogs',
    exact: true,
    component: loadable(() => import('../pages/blogs/index')),
    pageType: '博客主页',
  },
  {
    path: '/blogs/detail/:id',
    name: 'blogs/detail',
    exact: true,
    component: loadable(() => import('../pages/blogs/blog-detail')),
    pageType: '博客文章详情',
  },
  {
    path: '/me/order/:id',
    name: 'me',
    exact: true,
    isGreyBody: true,
    showFbMsg: true,
    component: loadable(() => import('../pages/account/order-detail/index')),
    pageType: '订单详情页',
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    exact: true,
    component: loadable(() => import('../pages/account/recovery/index')),
    pageType: '密码重置页',
  },
  {
    path: '/recovery',
    name: 'recovery',
    exact: true,
    component: loadable(() => import('../pages/account/recovery/index')),
    pageType: '店铺恢复页',
  },
  {
    path: '/close',
    name: 'close',
    exact: true,
    hideHeader: true,
    hideFooter: true,
    component: loadable(() => import('../pages/store-close')),
    pageType: '店铺关闭页',
  },
  {
    path: '/policy/:item',
    name: 'policy',
    exact: true,
    component: policy,
    pageType: '用户条款',
  },
  {
    path: '/discount/:id',
    name: 'discount',
    exact: true,
    component: loadable(() => import('../pages/discount/index')),
    pageType: '折扣详情页',
  },
  {
    path: '/payment/bogus',
    name: '/payment/bogus',
    exact: true,
    component: loadable(() => import('../pages/payment-bogus/index')),
    pageType: '支付中转页',
  },
  {
    path: '/callback/:type',
    canNotEditLayout: true,
    name: '/callback/:type',
    hideHeader: false,
    hideFooter: true,
    exact: true,
    component: callback,
    pageType: '第三方回调页面',
  },
  {
    path: '/form/:id',
    name: 'form',
    exact: true,
    component: loadable(() => import('../pages/form/index')),
    showFbMsg: true,
    canNotEditLayout: true,
    pageType: '自定义表单页',
    layout: false,
  },
  {
    path: '/form/:id/success',
    name: 'form',
    exact: true,
    component: loadable(() => import('../pages/form/index')),
    showFbMsg: true,
    canNotEditLayout: true,
    pageType: '自定义表单成功页',
    layout: false,
  },
  {
    path: '/share/:shortLink',
    name: 'share',
    exact: true,
    component: loadable(() => import('../pages/share/index')),
    showFbMsg: true,
    canNotEditLayout: true,
    pageType: '分享中转页',
    layout: false,
  },
  ...distributionRoute,
  ...groupBuyingRoute,
  ...activityRoute,
  ...memberRoute,
  ...authRoute,
  {
    path: '*',
    component: NotFindPage,
    exact: true,
  },
];
