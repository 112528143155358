import { queryToUrlString, getQueryString } from './index';
import isEmpty from 'lodash/isEmpty';
import { getUrl } from 'utils/formate';

export const getQueryStringOfDesign = (query = {}) => {
  const allQuery = query;
  const urlParams = getQueryString(window.location.search) || {};
  const { tid, mode } = urlParams;

  if (tid) {
    allQuery.tid = tid;
  }

  if (mode) {
    allQuery.mode = mode;
  }

  return isEmpty(allQuery) ? '' : `?${queryToUrlString(allQuery)}`;
};

export const jumpToTargetPageNeedSeo = (path) => {
  if (window.isEdit) return;
  const { origin } = window.location;
  window.location.href = `${origin}${path}${getQueryStringOfDesign()}`;
};

export const jumpToPage = (url = '', openWay = 'self') => {
  if (window.isEdit) return;
  if (!url) {
    console.info('jumpToPage： url不存在');
    return;
  }
  if (getUrl(url) === window.location.href.split('?')[0]) return;
  window.open(getUrl(url), `_${openWay}`);
};

export const pushToHistory = (history, path, query) => {
  if (window.isEdit) return;
  if (history) history.push(`${path}${getQueryStringOfDesign(query)}`);
};
