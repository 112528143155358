import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import goodsApi from 'api/goods';
import { Style } from './style';

export const GoodsTags = (props) => {
  const { goodsId } = props;
  const intl = useIntl();
  const [showSfExpress, setShowSfExpress] = useState(0);
  const [visiblePickupTag, setVisiblePickupTag] = useState(false);

  const getGlobalConfig = () => {
    goodsApi.getGlobalConfig().then((res) => {
      const { showSfExpress = 0 } = res || {};
      setShowSfExpress(showSfExpress);
    });
  };

  const getIsPickupGoods = () => {
    goodsApi
      .isPickupGoods({
        goodsId,
      })
      .then((res) => {
        setVisiblePickupTag(res === true);
      });
  };

  useEffect(() => {
    getGlobalConfig();
    getIsPickupGoods();
  }, []);

  return (
    <Style>
      {Boolean(showSfExpress) && (
        <div className='goods-detail-tag'>
          <svg className='icon goods-tag-icon' aria-hidden='true' width='16' height='16'>
            <use xlinkHref='#iconic-chenggong' />
          </svg>
          <span className='goods-tag-name'>
            {intl.formatMessage({
              description: '顺丰配送',
              id: 'b9ce38ed9a1f4eb99f13d058187d5ecd',
            })}
          </span>
        </div>
      )}
      {visiblePickupTag && (
        <div className='goods-detail-tag'>
          <svg className='icon goods-tag-icon' aria-hidden='true' width='16' height='16'>
            <use xlinkHref='#iconic-chenggong' />
          </svg>
          <span className='goods-tag-name'>
            {intl.formatMessage({
              description: '到店自提',
              id: '16618fd6664043e09f239532067dda09',
            })}
          </span>
        </div>
      )}
    </Style>
  );
};
