/* eslint-disable array-callback-return */
/* eslint-disable no-plusplus */
/* eslint-disable default-case */
/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
/**
color_text: "#4d1818" // 文本 - 标题和链接
color_body_text: "#3c4049"  // 文本-正文文本
color_sale_text // 文本 - 销售价格

color_button // 按钮 - 主要按钮
color_button_text // 按钮 - 主要标签
color_small_button_text_border // 按钮 - 次要标签和边框

color_text_field_text // 表单字端 - 文本
color_text_field_border // 表单字端 - 边框
color_text_field // 表单字端 - 背景

color_image_overlay_text: "#fff" // 图片叠加 - 文本
color_image_overlay: "#605050" // 图片叠加 - 背景
image_overlay_opacity: 45 // 图片叠加 - 不透明度

color_borders: "#d7d8d2" // 页面 - 边框和线条
color_body_bg: "#fafafa" // 页面-背景

favicon: "https://gw.alicdn.com/tps/TB1W_X6OXXXXXcZXVXXXXXXXXXX-400-400.png" // 网站图标
type_base_font: "SimSun" // 正文文本 - 字体
type_base_size: "19px" // 正文文本 - 基本尺寸
type_header_base_size: "32px" // 标题和按钮 - 标题基本尺寸
type_header_font: "SimSun" // 标题和按钮 - 字体
*/

// bg:rgba(var(234567, 1), 1);
// bg2:rgba(var(234567, 1), 50%);

// 50%

// rgba();
// var(, 1);
// var(abb, 0.5);
import { hexToRGB } from 'utils/color';
import shopApi from '../../api/shop';

const globalStyleConfig = {
  color_text: '#162950', // 文本 - 标题和链接
  color_body_text: '#6d6d6d', // 文本-正文文本
  color_sale_text: '#3A3A3A', // 文本 - 销售价格
  color_button: '#3A3A3A', // 按钮 - 主要按钮 链接颜色
  color_button_text: '#ffffff', // 按钮 - 主要标签
  color_small_button_text_border: '#162950', // 按钮 - 次要标签和边框
  color_image_overlay_text: '#ffffff', // 图片叠加 - 文本
  color_image_overlay: '#3d3d3d', // 图片叠加 - 背景
  image_overlay_opacity: '40', // 图片叠加 - 不透明度
  color_borders: '#e4e4e4', // 页面 - 边框和线条
  color_body_bg: '#f8f8f8', // 页面-背景
  type_base_font: 'muli_n4', // 正文文本 - 字体
  type_base_size: '18', // 正文文本 - 基本尺寸
  type_header_base_size: '26', // 标题和按钮 - 标题基本尺寸
  type_header_font: 'muli_n6', // 标题和按钮 - 字体
  type_sale_price_font: 'helvetica_n7', // 商品价格文本 - 字体
  type_sale_price_size: 100, // 商品价格文本 - 基本尺寸
  type_sale_origin_price_font: 'helvetica_n4', // 商品原价划线价文本 - 字体
  type_sale_origin_price_size: 100, // 商品原价划线价文本 - 基本尺寸
  color_text_field_text: '#162950', // 表单文本
  color_text_field_border: '#909090', // 表单边框
  color_text_field: '#ffffff', // 表单背景
  color_card: '#fff', // 卡片颜色
  color_activity_label: '#FF6D00', // 活动标签
  color_small_button_bg: 'rgba(255, 255, 255, 1)', // 次按钮背景
  // 圆角
  border_radius_btn: 0,
  border_radius_tag: 0,
  border_radius_form: 0,
  border_radius_pd_card: 0,
  border_radius_card: 0,
  border_radius_other: 0,
};

const formStyleConfig = {
  form_select_color: '#2654FF',
  form_title_color: '#646566',
  form_card_color: '#FFFFFF',
  form_page_bg_color: '#F7F8FA',
  form_border_color: '#DCDEE0',
  form_bg_color: '#FFFFFF',
  form_name_size: 'helvetica_n7',
  form_title_size: 'helvetica_n7',
  form_text_align: 'center',
  form_title_scale: 80,
};

// 先设置一份默认值以防显示bug
// const globalStyleConfigDefult = {
//     color_text: '#162950',
//     color_body_text: '#6d6d6d',
//     color_sale_text: '#3A3A3A',
//     color_borders: '#e4e4e4',
//     color_button: '#3A3A3A',
//     color_button_text: '#ffffff',
//     color_small_button_text_border: '#162950',
//     color_text_field: '#ffffff',
//     color_text_field_text: '#162950',
//     color_text_field_border: '#909090',
//     color_image_overlay_text: '#ffffff',
//     color_image_overlay: '#3d3d3d',
//     image_overlay_opacity: 40,
//     color_body_bg: '#f8f8f8',
//     type_header_font: 'muli_n6',
//     type_header_base_size: 26,
//     type_base_font: 'muli_n4',
//     type_base_size: 18,
//     type_sale_price_font: 'helvetica_n7',
//     type_sale_price_size: 100,
//     type_sale_origin_price_font: 'helvetica_n4',
//     type_sale_origin_price_size: 100,
//     share_facebook: true,
//     share_twitter: true,
//     share_pinterest: true,
//     social_twitter_link: '',
//     social_facebook_link: '',
//     social_pinterest_link: '',
//     social_instagram_link: '',
//     social_tumblr_link: '',
//     social_snapchat_link: '',
//     social_youtube_link: '',
//     social_vimeo_link: '',
//     favicon: '',
//     checkout_header_image: '',
//     checkout_logo_image: '',
//     checkout_logo_position: 'left',
//     checkout_logo_size: 'small',
//     checkout_body_background_image: '',
//     checkout_body_background_color: '#ffffff',
//     checkout_input_background_color_mode: 'white',
//     checkout_sidebar_background_image: '',
//     checkout_sidebar_background_color: '#e8f3f6',
//     checkout_heading_font: 'Roboto',
//     checkout_body_font: 'Source Sans Pro',
//     checkout_accent_color: '#1990c6',
//     checkout_button_color: '#23314e',
//     checkout_error_color: '#ff6d6d',
// };

export enum CssVarRangeType {
  form = 'form',
  global = 'global',
}

const styleConfigRange = {
  [CssVarRangeType.global]: globalStyleConfig,
  [CssVarRangeType.form]: formStyleConfig,
};

const needExchangeMap = {
  image_overlay_opacity: (value = 0) => {
    return value / 100;
  },
  type_header_base_size: (value) => {
    return value / 100;
  },
  type_base_size: (value) => {
    return value / 100 || 1;
  },
  form_title_scale: (value) => {
    return value / 100;
  },
};

export const setGlobalById = (name = '--main-color-title', value) => {
  const root = document.querySelector(':root');
  root.style.setProperty(name, value);
};

const fontStyleConfigMap = {
  n: 'normal',
  i: 'italic',
};

// 首字母大写
function titleCase(str) {
  const newStr = str.split(' ');
  for (let i = 0; i < newStr.length; i++) {
    newStr[i] = newStr[i].slice(0, 1).toUpperCase() + newStr[i].slice(1).toLowerCase();
  }
  return newStr.join(' ');
}
// font-family是跟进字体文件名按照-split然后每个单词大小写确定的
const setFontCssVary = (fontType, fontName, fontStyleName, fontWeight, root) => {
  const fontNameSet = titleCase(fontName.replace(/-/g, ' '));
  switch (fontType) {
    case 'header':
      root.style.setProperty('--font-stack-header', fontNameSet);
      root.style.setProperty('--font-style-header', fontStyleName);
      root.style.setProperty('--font-weight-header', fontWeight);
      break;
    case 'body':
      root.style.setProperty('--font-stack-body', fontNameSet);
      root.style.setProperty('--font-style-body', fontStyleName);
      root.style.setProperty('--font-weight-body', fontWeight);
      break;
    case 'sale_price':
      root.style.setProperty('--font-stack-sale-price', fontNameSet);
      root.style.setProperty('--font-style-sale-price', fontStyleName);
      root.style.setProperty('--font-weight-sale-price', fontWeight);
      break;
    case 'sale_origin_price':
      root.style.setProperty('--font-stack-sale-origin-price', fontNameSet);
      root.style.setProperty('--font-style-sale-origin-price', fontStyleName);
      root.style.setProperty('--font-weight-sale-origin-price', fontWeight);
      break;
  }
};
const fontTypeMap = {
  type_base_font: 'body',
  type_header_font: 'header',
  type_sale_price_font: 'sale_price',
  type_sale_origin_price_font: 'sale_origin_price',
};

const dealGlobalFont = (key, value, root) => {
  const fontType = fontTypeMap[key];
  // 解析key
  const arr = value.split('_');

  if (arr.length === 1) {
    // 赋予默认值
    setFontCssVary(fontType, arr[0], 'normal', 400, root);
  } else {
    setFontCssVary(fontType, arr[0], fontStyleConfigMap[arr[1].split('')[0]], (arr[1].split('')[1] - 0) * 100, root);
  }
};

export const initGlobalVarCss = (current, varCssConfig = {}) => {
  const { type, cssMountEle } = varCssConfig || {
    type: CssVarRangeType.global,
    cssMountEle: document.documentElement,
  };
  const root = cssMountEle;
  const styleConfig = styleConfigRange[type];
  console.log(styleConfigRange, '===styleConfigRange===');
  Object.keys(styleConfig).map((key) => {
    // if (globalStyleConfig[key] === current[key]) return; // 设置前后值没变直接return
    const cssVarName = `--${key}`;
    const valueOfCssVar = needExchangeMap[key]
      ? needExchangeMap[key](current[key] || styleConfig[key])
      : current[key] !== undefined
      ? current[key]
      : styleConfig[key];
    styleConfig[key] = current[key] || styleConfig[key];
    // 十六进制-rgba, 因为需要设置透明度
    if (typeof valueOfCssVar === 'string' && valueOfCssVar?.indexOf('#') !== -1) {
      root.style.setProperty(`${cssVarName}_rgb`, hexToRGB(valueOfCssVar));
    }

    // 字体处理
    if (['type_header_font', 'type_base_font', 'type_sale_price_font', 'type_sale_origin_price_font'].includes(key)) {
      dealGlobalFont(key, valueOfCssVar, root);
    }

    // 圆角处理
    if (
      [
        'border_radius_btn',
        'border_radius_tag',
        'border_radius_form',
        'border_radius_pd_card',
        'border_radius_card',
        'border_radius_other',
      ].includes(key)
    ) {
      if (valueOfCssVar) {
        return root.style.setProperty(cssVarName, `var(--border-radius-r${Math.round(valueOfCssVar / 4)})`);
      }
    }
    return root.style.setProperty(cssVarName, valueOfCssVar);
  });
};

export const addFavicon = async () => {
  const { kdtId } = window?.global_data?.shopInfo;
  const favicon = (await shopApi.getShopLogo({ kdtId }))?.shopLogoUrl;
  if (!favicon) return;
  const links = Array.from(document.getElementsByTagName('link'));
  links.map((link) => link.rel === 'icon' && document.head.removeChild(link));
  const link = document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'icon';
  link.href = favicon;
  document.getElementsByTagName('head')[0].appendChild(link);
};

export const initGlobalStyle = (
  config = {},
  varCssConfig = {
    type: CssVarRangeType.global,
    cssMountEle: document.documentElement,
  },
) => {
  const { current = {} } = config;
  initGlobalVarCss(current, varCssConfig);
};

const getFontCssVary = (fontType, fontName, fontStyleName, fontWeight) => {
  const fontList = [];
  const fontNameSet = titleCase(fontName.replace(/-/g, ' '));

  switch (fontType) {
    case 'header':
      fontList.push(`--font-stack-header: ${fontNameSet}`);
      fontList.push(`--font-style-header: ${fontStyleName}`);
      fontList.push(`--font-weight-header: ${fontWeight}`);
      break;
    case 'body':
      fontList.push(`--font-stack-body: ${fontNameSet}`);
      fontList.push(`--font-style-body: ${fontStyleName}`);
      fontList.push(`--font-weight-body: ${fontWeight}`);
      break;
    case 'sale_price':
      fontList.push(`--font-stack-sale-price: ${fontNameSet}`);
      fontList.push(`--font-style-sale-price: ${fontStyleName}`);
      fontList.push(`--font-weight-sale-price: ${fontWeight}`);
      break;
    case 'sale_origin_price':
      fontList.push(`--font-stack-sale-origin-price: ${fontNameSet}`);
      fontList.push(`--font-style-sale-origin-price: ${fontStyleName}`);
      fontList.push(`--font-weight-sale-origin-price: ${fontWeight}`);
      break;
  }
  return fontList;
};

const getGlobalFont = (key, value) => {
  const fontType = fontTypeMap[key];
  // 解析key
  const arr = value.split('_');
  if (arr.length === 1) {
    // 赋予默认值
    return getFontCssVary(fontType, arr[0], 'normal', 400);
  } else {
    return getFontCssVary(fontType, arr[0], fontStyleConfigMap[arr[1].split('')[0]], (arr[1].split('')[1] - 0) * 100);
  }
};
/** 用于服务端 */
export const getGlobalVarCss = (current) => {
  let list = [];
  Object.keys(globalStyleConfig).map((key) => {
    const cssVarName = `--${key}`;
    const valueOfCssVar = needExchangeMap[key]
      ? needExchangeMap[key](current[key])
      : current[key]
      ? current[key]
      : globalStyleConfig[key];
    globalStyleConfig[key] = current[key] || globalStyleConfig[key];
    // 十六进制-rgba, 因为需要设置透明度
    if (typeof valueOfCssVar === 'string' && valueOfCssVar?.indexOf('#') !== -1) {
      list.push(`${cssVarName}_rgb: ${hexToRGB(valueOfCssVar)}`);
    }
    // 字体处理
    if (['type_header_font', 'type_base_font', 'type_sale_price_font', 'type_sale_origin_price_font'].includes(key)) {
      const fontList = getGlobalFont(key, valueOfCssVar);
      list = [...list, ...fontList];
    }
    list.push(`${cssVarName}: ${valueOfCssVar}`);
  });
  return list.join(';');
};
