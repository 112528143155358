import BaseApi from './base';
import { set } from 'lodash';

class OrderApi extends BaseApi {
  getHistoryOrderByToken(params) {
    return this._post('/api/soa/com.youzan.i18n.order.controller.web.OrderController/getHistoryOrderByToken', params);
  }
  /**
   */
  searchOrderWithPage(params) {
    return this._post('/api/soa/com.youzan.i18n.order.controller.web.OrderController/searchOrderWithPage', params);
  }
  // 保存弃单联系信息
  createOrder(params) {
    return this._post('/api/soa/com.youzan.i18n.order.controller.web.OrderController/createOrder', params, {
      throttle: true,
    });
  }

  // 获取弃单发货信息
  updateOrder(params) {
    return this._get('/api/soa/com.youzan.i18n.order.controller.web.OrderController/updateOrder', params);
  }

  // 获取订单详情
  getOrderByToken(params) {
    return this._get('/api/soa/com.youzan.i18n.order.controller.web.OrderController/getOrderByToken', params);
  }

  // 保存弃单发货信息
  saveCheckoutShipInfo(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.goods.customer.web.GoodsCustomerController/saveCheckoutShipInfo',
      params,
    );
  }

  // 获取弃单发货信息
  getCheckoutByToken(params) {
    return this._get('/api/soa/com.youzan.i18n.checkout.controller.web.CheckoutController/getCheckoutByToken', params);
  }

  // 订单支付
  payOrder(params) {
    // 订单来源
    try {
      const source = new URLSearchParams(location.search).get('source');
      if (source === 'email') { // 弃单: email
        set(params, 'isFromCheckout', 1);
      }
    } catch (e) {}
    return this._post('/api/soa/com.youzan.i18n.order.controller.web.OrderController/payOrder', params, {
      commonErrorHandle: false,
      throttle: true,
    });
  }

  // 支付订单
  getPaymentGateways(params) {
    return this._get('/api/soa/com.youzan.i18n.order.controller.web.OrderController/getPaymentGateways', params, {
      throttle: true,
    });
  }

  // 收银台管理 新的支付方式
  getPaymentGatewaysByCategory(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.asset.customer.controller.web.PaymentCustomerController/getPaymentGatewaysByCategory',
      params,
      {
        throttle: true,
      },
    );
  }

  // 收银台管理 新的支付方式 新版本接口
  getGroupedPaymentGateways(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.order.controller.web.OrderController/getGroupedPaymentGateways',
      params,
      {
        throttle: true,
      },
    );
  }

  // 获取支付 SDK 公钥
  getPaymentPublicKey(params) {
    return this._get('/api/soa/com.youzan.i18n.asset.controller.web.PaymentController/getPublicSecretV2', params);
  }

  // 获取订单状态
  getOrderStatusByToken(params) {
    return this._get('/api/soa/com.youzan.i18n.order.controller.web.OrderController/getOrderStatusByToken', params);
  }

  // 创建用户
  createCustomer(params) {
    return this._post('/api/soa/com.youzan.i18n.customer.controller.web.CustomerController/createCustomer', params, {
      throttle: true,
    });
  }

  chooseShippingRate(params) {
    return this._post('/api/soa/com.youzan.i18n.order.controller.web.OrderController/chooseShippingRate', params, {
      throttle: true,
    });
  }

  // 应用折扣码
  applyDiscountCode(params) {
    return this._post('/api/soa/com.youzan.i18n.order.controller.web.OrderController/applyDiscountCode', params, {
      throttle: true,
    });
  }

  // 获取折扣码详情
  getDiscountCodeById(params) {
    return this._post('/api/soa/com.youzan.i18n.ump.admin.web.DiscountCodeController/getDiscountCodeById', params, {
      throttle: true,
    });
  }

  // 取消折扣码
  withdrawDiscountCode(params) {
    return this._post('/api/soa/com.youzan.i18n.order.controller.web.OrderController/withdrawDiscountCode', params);
  }

  // 获取订单折扣详情
  getOrderPriceDetailByToken(params) {
    return this._post(
      '/api/soa/com.youzan.i18n.order.controller.web.OrderController/getOrderPriceDetailByToken',
      params,
      {
        throttle: true,
      },
    );
  }
  // 创建快捷订单
  expressCheckout(params) {
    return this._post('/api/soa/com.youzan.i18n.order.controller.web.OrderController/expressCheckout', params);
  }

  // 获取购物车商品数据
  getCartProducts(params) {
    return this._get('/cart/products', params);
  }

  // 购物车购买完毕后清空购物车
  cleartCarts(params) {
    return this._get('/cart/clear', params);
  }

  calculatePrice(params) {
    return this._post('/api/soa/com.youzan.i18n.order.controller.web.OrderController/calculatePrice', params, {
      throttle: true,
    });
  }

  // 快捷支付 确认付款
  manualCapturePayment(params) {
    return this._post('/api/soa/com.youzan.i18n.order.controller.web.OrderController/manualCapturePayment', params, {
      throttle: true,
    });
  }
  // paypal 快捷支付
  expressPay(params) {
    return this._post('/api/soa/com.youzan.i18n.order.controller.web.OrderController/expressPay', params, {
      throttle: true,
    });
  }

  calculateCart(params) {
    return this._post(
      '/api/soa/com.youzan.i18n.ump.price.controller.web.PromotionPriceController/calculateCart',
      params,
      {
        throttle: true,
      },
    );
  }

  // 支付网关授权
  handlePayment(params) {
    return this._post(
      '/api/soa/com.youzan.i18n.payment.gateway.controller.BogusGatewayController/handlePayment',
      params,
    );
  }

  // 获取支付订单的状态
  getOrderPaymentStatus(params) {
    return this._post('/api/soa/com.youzan.i18n.order.controller.web.OrderController/getOrderPaymentStatus', params);
  }
  // 根据多币种获取收银台管理 新的支付方式 新版本接口
  getGroupedPaymentGatewaysByCurrency(params) {
    return this._get(
      '/api/soa/com.youzan.i18n.order.controller.web.OrderController/getGroupedPaymentGatewaysByCurrency',
      params,
      {
        throttle: true,
      },
    );
  }
  // 生成订单前预览价格
  pricePreview(params) {
    return this._get('/api/soa/com.youzan.i18n.order.controller.web.OrderController/pricePreview', params, {
      throttle: true,
    });
  }
  // 取消应用折扣码
  cancelApplyDiscountCode(params) {
    return this._post('/api/soa/com.youzan.i18n.order.controller.web.OrderController/cancelApplyDiscountCode', params, {
      throttle: true,
    });
  }

  // 保存订单
  saveOrder(params) {
    return this._post('/api/soa/com.youzan.i18n.order.controller.web.OrderController/saveOrder', params, {
      throttle: true,
    });
  }

  // 订单初始化
  getCheckoutPreviewDetail(params) {
    return this._post('/api/soa/com.youzan.i18n.order.controller.web.OrderController/getCheckoutPreviewDetail', params);
  }

  // 订单数据更新
  checkoutPreview(params) {
    return this._post('/api/soa/com.youzan.i18n.order.controller.web.OrderController/checkoutPreview', params);
  }

  getByReceiptId(params) {
    return this._post('/api/soa/com.youzan.i18n.receipt.controller.web.ReceiptController/getByReceiptId', params);
  }

  // 下载订单自定义结算文件
  getDownloadAttachFileUrl = (params) => {
    return this._post(
      '/api/soa/com.youzan.i18n.order.controller.web.OrderController/getDownloadAttachFileUrl',
      params,
    );
  }
}

export default new OrderApi();
