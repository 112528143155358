import get from 'lodash/get';
import { loadTiktokPixel } from './tiktok-pixel';
import GoogleAnalytics from './google-Analytics';
import { loadFacebookPixel } from './facebook-pixel';
import ThirdPlatformApi from '../../api/third-platform';

import { getQueryString } from '../index';
import { loadThirdJavascript, THIRD_JAVASCRIPT_CONFIG } from './load-third-javascript';

const initVconsle = () => {
  const { vconsole } = getQueryString(document.location.search);

  vconsole === '1' &&
    loadThirdJavascript(THIRD_JAVASCRIPT_CONFIG.VCONSOLE.name, THIRD_JAVASCRIPT_CONFIG.VCONSOLE.url, true).then(() => {
      try {
        var vConsole = new VConsole();
        console.log('Hello world');
      } catch (err) {
        console.warn('vConsole 初始化失败 ================>');
        console.warn(err);
        console.warn('vConsole 初始化失败 ================<');
      }
    });
};

const sdkStart = () => {
  initVconsle();
  const kdtId = global_data?.kdtId;
  if (!kdtId) return;
  ThirdPlatformApi.getDataAnalyticsSetting({ kdtId }).then((res) => {
    const { googleAnalyticsId, googleTagManagerSwitch, tiktokPixelId, googleTagManagerHead, googleTagManagerBody } =
      res || {};
    if (tiktokPixelId) {
      loadTiktokPixel(tiktokPixelId);
    }
    const gtmId = googleTagManagerHead || googleTagManagerBody;
    new GoogleAnalytics(googleAnalyticsId, googleTagManagerSwitch, gtmId);
  });

  ThirdPlatformApi.getAllPixel({ kdtId }).then((res) => {
    const fbPixelId = get(res, '[0].fbPixelId', '');
    fbPixelId && loadFacebookPixel(fbPixelId);
  });
};

export default sdkStart;
